@use "sass:map";

.text-block-with-image-right {
    background-color: $white;
    padding: 159px 0 150px 0;

    @include media('<769px') {
        padding: 60px 0 60px 0;
    }

    .subtitle {
        font-size: rem-calc(30);
        line-height: lh(25,30);
        font-weight: 500;
        color: #CCE1EE;
        display: block;
        margin-bottom: 14px;
        text-transform: initial;

        @include media('<medium') {
            font-size: rem-calc(25);
            line-height: lh(25,25);
            margin-bottom: 5px;
        }
    }

    h2 {
        font-size: rem-calc(45);
        font-weight: 300;
        line-height: lh(50,45);
        margin-bottom: 38px;

        @include media('<medium') {
            font-size: rem-calc(30);
            line-height: lh(44,30);
            margin-bottom: 28px;
        }
    }

    p {
        margin-bottom: 30px;
        max-width: 392px;

        @include media('<medium') {
            margin-bottom: 1rem;
        }

        @include media('<medium') {
            font-size: rem-calc(14);
            line-height: lh(27,14);
        }

        &:last-of-type {
            margin-bottom: 41px;

            @include media('<medium') {
                margin-bottom: 1rem;
            }
        }
    }

    .btn-link {
        font-size: rem-calc(17);
    }

    .parallax-image {
        margin-top: -8px;
        float: right;
        height: 558px;
        width: 651px;
        max-width: 100%;

        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media('<medium') {
            margin-top: 50px;
            height: 300px;
            width: 100%;
        }

        @supports (-webkit-touch-callout: none) {
            background-attachment: unset;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
