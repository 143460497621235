@use "sass:map";
$header-background-color: rgba($dark-blue, 0.95);
$header-padding: 30px 0;
$header-logo-max-width: 100px;

/*
 * Styling for the header
 */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 30;

    &.bg-light {
        .header__nav>ul>li>a {
            color: $dark-blue;
        }

        &.header-with-submenus {
            .has-children ul {
                background-color: $white;
            }
            .menu-item>ul>li>a {
                color: $dark-blue;
                border-color: $bg-color;
            }
            .has-children::before {
                background-color: $white;
            }
        }

        .header__menutrigger {
            background-color: $lighter-blue;
        }
    }

    &.scrolled {
        .header__wrapper {
            height: 82px;
            background-color: $header-background-color;
        }

        .header__logo {
            width: 275px;
            clip-path: polygon(0 0, 100% 0%, calc(100% - 55px) 100%, 0% 100%);
            //padding-left: 40px;

            @include media('<medium') {
                width: calc(50% + 55px);
            }

            img {
                width: 150px;
            }
        }

        .header__menutrigger {
            @include media('<medium') {
                margin-top: 0;
            }
        }
    }

    &.bg-light {
        .header__nav>ul>li>a {
            color: $dark-blue;
        }

        &.header-with-submenus {
            .has-children ul {
                background-color: $white;
            }

            .menu-item>ul>li>a {
                color: $dark-blue;
                border-color: $bg-color;
            }

            .has-children::before {
                background-color: $white;
            }
        }

        .header__menutrigger {
            background-color: $lighter-blue;
        }

        &.scrolled {
            .header__wrapper {
                background-color: $white;
                border-bottom: 1px solid $bg-color;
            }
        }
    }

    &.menu-open {
        .fullscreen-menu {
            right: 0;

            .menu-wrapper .column2 .fullscreen-menu-socials {
                @include media('<medium') {
                    left: 67px;
                    position: fixed;
                    bottom: 30px;
                }
            }
        }

        .header__wrapper {
            background-color: transparent;

            .header__nav {
                opacity: 0;
            }
        }

        .header__menutrigger {
            .icon {
                &::before {
                    transform: translateY(50%) rotate(45deg);
                    top: 0;
                }

                &::after {
                    transform: translateY(-50%) rotate(-45deg);
                    top: 2px;
                }
            }

            .base-line {
                opacity: 0;
            }
        }

        &.scrolled {
            .header__menutrigger {
                @include media('<medium') {
                    margin-top: 20px;
                }
            }
        }
    }

    &__wrapper {
        height: 131px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transition: height .2s linear;

        > .header__logo {
            //animation: animateHeader ease-in-out .75s;
            //animation-fill-mode: forwards;
            //animation-delay: .5s;
            //opacity: 0;
        }

        @media (max-width: 992px) {
            height: 82px;
        }
    }

    &__logo {
        background: white;
        height: 100%;
        width: 438px;
        padding-left: 62px;
        display: flex;
        align-items: center;
        clip-path: polygon(0 0, 100% 0%, calc(100% - 86px) 100%, 0% 100%);
        transition: width .2s linear, clip-path .2s ease-in;

        @include media('<1600px') {
            width: 350px;

            img {
                width: 180px;
            }
        }
        
        @include media('<1200px') {
            width: 300px;
            padding-left: 40px;

            img {
                width: 160px;
            }
        }

        @include media('<medium') {
            width: calc(50% + 61px);
            padding-left: 40px;
            clip-path: polygon(0 0, 100% 0%, calc(100% - 53px) 100%, 0% 100%);
        }

        img {
            @include media('<large') {
                width: 153px;
            }
        }
    }

    &__nav {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;
        padding-right: 76px;

        //animation: animateNav ease-in-out .75s;
        //animation-fill-mode: forwards;
        //animation-delay: 1.25s;
        //opacity: 0;

        @include media('<1600px') {
            padding-right: 40px;
        }

        @include media('<1025px') {
            padding-right: 20px;
        }

        .btn {
            padding: 13px 40px 13px 34px;

            @include media('<1600px') {
                font-size: rem-calc(16);
            }

            @include media('<1400px') {
                display: none;
            }
        }

        > ul {
            display: flex;
            gap: 53px;
            margin-right: 37px; //56px;

            @include media('<1726px') {
                gap: 26px;
                margin-right: 26px;
            }

            @include media('<1025px') {
                gap: 20px 13px;
                margin-right: 0;
            }

            @include media('<large') {
                margin-right: 0;
                margin-top: 0;
                gap: 20px;
                flex-direction: column;
                display: none;
            }

            > li {
                display: inline;
                margin: 0;
                margin-right: -1px;

                &.is-active {
                    a {
                        background: transparent;

                        &::before {
                            width: 100%;
                        }
                    }
                }

                @include media('<large') {
                    display: block;
                    text-align: center;
                }

                > a {
                    font-size: rem-calc(18);
                    font-weight: 400;
                    padding: 0;
                    color: $white;
                    position: relative;

                    @include media('<1726px') {
                        gap: 26px;
                    }

                    @include media('<1600px') {
                        font-size: rem-calc(16);
                    }

                    @include media('<1025px') {
                        font-size: rem-calc(15);
                    }

                    &::before {
                        content: "";
                        width: 0;
                        height: 1px;
                        background-color: $input-border;
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        transition: width .3s ease-in, opacity .3s ease-in;
                    }
                }

                &:hover,&:focus {
                    &:not(.has-children) {
                        > a {
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &__menutrigger {
        display: block;
        border-radius: 50px;
        background-color: rgba(232, 241, 247, .3);
        width: 68px;
        height: 56px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color .15s ease-out;
        margin-top: -6px;

        @include media('<medium') {
            width: 60px;
            height: 49px;
            margin-top: 34px;
            margin-right: 20px;
        }

        .icon {
            display: block;
            height: 2px;
            width: 19px;
            margin: 0 auto;
            position: relative;
            transition: padding .15s ease-out, height .15s ease-out;
            left: -1px;

            &::before,
            &::after  {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                width: 19px;
                height: 100%;
                background: #FFF;
                transition: transform .15s ease-out;
            }

            &::before {
                top: -6px;
            }

            &::after {
                top: 6px;
            }

            .base-line {
                display: block;
                width: 19px;
                height: 100%;
                background: #FFF;
                transition: background-color .15s ease-out;
            }
        }

        &:hover,&:focus {
            background-color: $darker-blue;
        }

        @include media('<large') {
            display: flex;
        }
    }
}

.fullscreen-menu {
    z-index: 999;
    position: fixed;
    height: 100vh;
    width: 100%;
    right: -100%;
    top: 0;
    transition: right .3s ease-in;

    .header__logo {
        height: 131px;
        z-index: 1;
        position: relative;

        @include media('<1600px') {
            width: 350px;

            img {
                width: 180px;
            }
        }

        @include media('<large') {
            height: 80px;
        }

        @include media('<medium') {
            width: calc(50% + 55px);
        }

        img {
            @include media('<large') {
                width: 120px;
            }
        }
    }

    .header__menutrigger {
        position: absolute;
        top: 39px;
        right: 75px;
        z-index: 1;

        @include media('<medium') {
            top: 0px;
            right: 20px;
        }



        .icon {
            &::before {
                transform: translateY(50%) rotate(45deg);
                top: 0;
            }

            &::after {
                transform: translateY(-50%) rotate(-45deg);
                top: 2px;
            }
        }

        .base-line {
            opacity: 0;
        }
    }

    .menu-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        height: 100%;

        @include media('<medium') {
            flex-direction: column;
            overflow: scroll;
        }

        .column1 {
            background-color: rgba($dark-blue, 0.66);
            backdrop-filter: blur(10px);
            flex: 1;
        }

        .column2 {
            background-color: $dark-blue;
            width: 700px;
            height: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            @include media('<large') {
                padding-left: 40px;
            }

            @include media('<medium') {
                width: 100%;
                padding: 20px 67px 26px 67px;
                height: auto;
            }

            .fullscreen-menu-socials {
                display: flex;

                @include media('<medium') {
                    display: none;
                }
            }

            nav {
                margin-top: 131px;
                max-width: 454px;
                width: 100%;

                @include media('<medium') {
                    margin-top: 91px;
                }

                ul {
                    li {
                        border-bottom: 1px solid $darker-blue;
                        margin-bottom: 16px;

                        @include media('<large') {
                            margin-bottom: 0;
                        }

                        &:last-child {
                            border-bottom: 0;
                        }

                        a {
                            color: $white;
                            font-size: rem-calc(45);
                            line-height: lh(75,45);
                            font-weight: 700;
                            padding: 10px 0 15px 0;
                            position: relative;
                            display: inline-block;

                            @include media('<large') {
                                font-size: rem-calc(30);
                            }

                            @include media('<medium') {
                                font-size: rem-calc(20);
                                padding: 10px 0 6px 0;
                            }

                            &::before {
                                content: "";
                                width: 0;
                                height: 1px;
                                background-color: $input-border;
                                position: absolute;
                                left: 0;
                                bottom: 28px;
                                transition: width .3s ease-in, opacity .3s ease-in;
                            }

                            &:hover {
                                &::before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .column3 {
            background-color: $darker-blue;
            width: 582px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .fullscreen-menu-socials {
                display: none;

                @include media('<medium') {
                    display: flex;
                    position: relative;
                    left: 0;
                    padding-left: 67px;
                    bottom: auto;
                    margin-bottom: 40px;
                }
            }

            @include media('<medium') {
                width: 100%;
            }

            .menu-title {
                font-size: rem-calc(30);
                line-height: lh(39,30);
                margin-bottom: 29px;
                color: $white;
                font-weight: 400;
                display: block;

                @include media('<medium') {
                    font-size: rem-calc(20);
                    margin-bottom: 27px;
                }
            }

            nav {
                margin-top: 149px;
                width: 100%;
                margin-left: 68px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include media('<large') {
                    margin-left: 40px;
                }

                @include media('<medium') {
                    margin-top: 0;
                    padding: 40px 67px;
                    margin-left: 0;
                }

                .btn + .btn {
                    margin-top: 24px;

                    @include media('<medium') {
                        margin-top: 10px;
                    }
                }

                ul {
                    margin-left: 3px;
                    max-width: 236px;
                    margin-bottom: 25px;
                    width: 100%;

                    @include media('<medium') {
                        margin-left: 0;
                        margin-bottom: 29px;
                    }

                    li {
                        border-bottom: 1px solid $dark-blue;

                        &:last-child {
                            border-bottom: 0;
                        }

                        a {
                            color: $white;
                            font-size: rem-calc(25);
                            line-height: lh(33,25);
                            font-weight: 300;
                            padding: 10px 0 15px 0;
                            position: relative;
                            display: inline-block;
                            transition: padding .2s ease-in;

                            @include media('<medium') {
                                font-size: rem-calc(17);
                                padding: 10px 0 8px 0;
                            }

                            &:hover {
                                padding: 10px 0 15px 15px;
                                background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
                                background-repeat: no-repeat;
                                background-position: 0 center;
                            }
                        }
                    }
                }
            }

            .fullscreen-menu-contact {
                color: $white;
                font-size: rem-calc(14);
                margin-left: 73px;
                margin-bottom: 55px;

                @include media('<large') {
                    margin-left: 40px;
                }

                @include media('<medium') {
                    display: none;
                }

                h3 {
                    font-size: rem-calc(18);
                    font-weight: 500;
                    margin-bottom: 14px;
                }

                a {
                    color: $white;
                    text-decoration: underline;
                    margin-bottom: 4px;
                    display: inline-block;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                p {
                    margin-bottom: 44px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .fullscreen-menu-socials {
            position: absolute;
            left: 121px;
            bottom: 55px;
            display: flex;
            gap: 20px;

            @include media('<large') {
                left: 40px;
            }

            a {
                color: $white;
                background-color: $darker-blue;
                width: 63px;
                height: 46px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color .3s ease-in;

                @include media('<medium') {
                    background-color: $dark-blue;
                    width: 44px;
                    height: 31px;
                    font-size: rem-calc(12);
                }

                &:hover {
                    background-color: $white;
                    color: $dark-blue;
                }
            }
        }
    }
}

.page-home {
    .header__wrapper>.header__logo {
        animation: animateHeader ease-in-out .75s;
        animation-fill-mode: forwards;
        animation-delay: .5s;
        opacity: 0;
    }

    .header__nav {
        animation: animateNav ease-in-out .75s;
        animation-fill-mode: forwards;
        animation-delay: 1.25s;
        opacity: 0;
    }
}


@keyframes animateHeader {
    0% {
        transform: translateX(-450px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes animateNav {
    0% {
        transform: translateY(-133px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
