@use "sass:map";

.expertise-tabs {
    padding: 155px 0 192px 0;
    border-top: 1px solid $lighter-blue;

    @include media('<large') {
        padding: 100px 0 100px 0;
    }

    @include media('<medium') {
        padding: 80px 0 103px 0;
    }

    h2 {
        font-size: rem-calc(110);
        font-weight: 500;
        line-height: lh(75,110);
        color: $dark-blue;
        margin-bottom: 79px;
        z-index: 2;
        position: relative;

        @include media('<large') {
            font-size: rem-calc(90);
        }

        @include media('<medium') {
            font-size: rem-calc(70);
            line-height: lh(75,70);
            margin-bottom: 42px;
        }
    }

    ul.tabs {
        max-width: 342px;
        background: rgba($dark-blue, .92);
        border-color: transparent;
        z-index: 2;
        position: relative;

        @include media('<medium') {
            max-width: 274px;
        }

        li.tabs-title {
            margin-bottom: 1px;

            &.is-active,
            &:hover {
                a {
                    background-color: transparent;
                    padding: 32px 20px 22px 69px;
                    background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
                    background-repeat: no-repeat;
                    background-position: 47px center;
                    background-size: 6px;

                    @include media('<medium') {
                        padding: 22px 20px 16px 68px;
                    }
                }
            }

            a {
                color: $white;
                font-size: rem-calc(25);
                line-height: lh(25,25);
                font-weight: 400;
                padding: 32px 20px 22px 47px;
                border-top: 1px solid $black;
                transition: padding .25s ease-in;

                @include media('<medium') {
                    font-size: rem-calc(20);
                    line-height: lh(25,20);
                    padding: 22px 20px 16px 38px;
                }
            }

            &:first-child {
                a {
                    border-top: 0;
                }
            }
        }
    }

    .tabs-content {
        max-width: 1015px;
        background: $white;
        margin-left: auto;
        margin-top: -360px;
        z-index: 1;
        position: relative;

        @include media('<large') {
            max-width: 800px;
        }

        @include media('<medium') {
            margin-top: -35px;
            margin-right: -40px;
        }

        .tabs-panel {
            padding: 0;

            .tab-image {
                @include media('<medium') {
                    height: 265px;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }


            }

            &.is-active {

            }

            .tab-body {
                padding: 100px 100px 108px 100px;

                @include media('<medium') {
                    padding: 44px 40px 259px 39px;
                }

                .subtitle {
                    font-size: rem-calc(30);
                    line-height: lh(25,30);
                    font-weight: 500;
                    color: #CCE1EE;
                    display: block;
                    margin-bottom: 18px;
                    text-transform: capitalize;

                    @include media('<medium') {
                        font-size: rem-calc(25);
                        line-height: lh(25,25);
                        margin-bottom: 5px;
                    }
                }

                h3 {
                    font-size: rem-calc(45);
                    font-weight: 300;
                    line-height: lh(44,45);
                    margin-bottom: 47px;

                    @include media('<medium') {
                        font-size: rem-calc(30);
                        line-height: lh(44,30);
                        margin-bottom: 28px;
                    }
                }

                p {
                    margin-bottom: 53px;
                    max-width: 392px;

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(27,14);
                        margin-bottom: 30px;
                    }
                }

                .btn-link {
                    font-size: rem-calc(17);
                }

                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    @include media('<medium') {
                        right: 0;
                        width: 285px;
                        bottom: -9px;
                    }
                }
            }
        }
    }
}
