@use "sass:map";

.vacancies-header {
    padding: 248px 0 77px 0;

    @include media('<large') {
        padding: 150px 0 60px 0;
    }

    .content {
        max-width: 580px;
    }

    h1 {
        font-size: rem-calc(50);
        line-height: lh(50,50);
        margin-top: 20px;
        margin-bottom: 66px;

        @include media('<large') {
            font-size: rem-calc(30);
            line-height: lh(30,30);
            margin-bottom: 20px;
        }
    }

    p {
        font-size: rem-calc(18);
        line-height: lh(30,18);
        margin-bottom: 0;

        @include media('<medium') {
            font-size: rem-calc(16);
            line-height: lh(30,16);
        }
    }

    .video-wrapper {
        width: 580px;
        height: 326px;
        max-width: 100%;
        margin-left: auto;
        margin-top: 141px;
        position: relative;
        overflow: hidden;

        @include media('<medium') {
            margin-top: 40px;
            height: 250px;
            margin-left: 0;
        }

        .youtube-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
            border: 0;
        }
    }
}
