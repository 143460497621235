@use "sass:map";

.homepage-header {
    width: 100%;
    height: 1080px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @include media('<medium') {
        height: 932px;
    }

    .columns {
        z-index: 1;
    }

    .fly-away {
        animation: flyAway 1s ease-out;
        animation-fill-mode: forwards;
    }

    .fly-back {
        animation: flyBack 1s ease-out;
        animation-fill-mode: forwards;

    }

    .content {
        padding-top: 383px;
        transition: all .2s ease-in;

        @include media('<medium') {
            padding-top: 269px;
        }
    }

    h1 {
        color: $white;
        font-size: rem-calc(70);
        line-height: lh(75,70);
        margin-bottom: 35px;

        @include media('<medium') {
            font-size: rem-calc(45);
            line-height: lh(50,45);
            margin-bottom: 26px;
        }
    }

    .btn-icon {
        padding: 19px 47px 18px 74px;
        backdrop-filter: blur(12px);

        &:hover {
            backdrop-filter: blur(50px);
        }

        @include media('<medium') {
            padding: 12px 23px 10px 53px;
            background-size: 7px;
            background-position: 29px center;
        }
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, rgba(27,41,74,1) 0%, rgba(27,41,74,0.56) 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &.parallax {
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover; //contain;

        @include media('<1920px') {
            background-size: cover;
            background-position: center;
        }

        @supports (-webkit-touch-callout: none) {
            background-attachment: unset;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}


@keyframes flyAway {
    to {
        transform: translateY(-400px);
        opacity: 0;
    }
}

@keyframes flyBack {
    from {
        transform: translateY(-400px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
