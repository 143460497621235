@use "sass:map";

$sidespace: calc((100vw - 1235px) / 2);

.process-slider {
    background-color: $darker-blue;
    padding: 370px 0 198px 0;

    @include media('<large') {
        padding: 300px 0 60px 0;
    }

    @include media('<769px') {
        padding: 250px 0 60px 0;
    }

    .columns {
        padding-left: $sidespace;
        padding-right: 0;

        @include media('<1280px') {
            padding-left: 40px;
        }
    }

    h2 {
        color: rgba($bg-color, .2);
        font-size: rem-calc(70);
        line-height: lh(75,70);
        margin-bottom: 41px;
        font-weight: 500;

        @include media('<medium') {
            font-size: rem-calc(40);
            line-height: lh(40,40);
        }
    }

    &__wrapper {
        .glide-process-slider {

        }

        .glide__slides {

        }

        .glide__slide {
            height: auto;
            padding-bottom: 140px;
            margin-bottom: 100px;
            position: relative;
            display: flex;

            @include media('<769px') {
                margin-bottom: 50px;
            }

            &:first-child {
                .process-slide {
                    .step-counter {
                        left: calc(50% - 12px);
                    }
                }
            }

            &:last-child {
                .process-slide {
                    &::after {
                        display: none;
                        width: 50%;
                    }

                    .step-counter {
                        left: calc(50% - 12px);
                    }
                }
            }
        }

        .process-slide {
            max-width: 403px;
            background-color: $white;

            .step-counter {
                background-color: $yellow;
                color: $white;
                width: 92px;
                height: 56px;
                border-radius: 50px;
                position: absolute;
                bottom: 6px;
                gap: 16px;
                display: flex;
                left: 50%; //calc(50% - 12px);
                transform: translateX(-50%);
                align-items: center;
                justify-content: center;

                span {
                    font-size: rem-calc(25);
                    line-height: lh(25,25);
                    font-weight: 500;
                    top: 2px;
                    position: relative;
                }
            }

            &::after {
                content: "";
                width: calc(100% + 57px);
                height: 2px;
                background-color: $yellow;
                position: absolute;
                left: 50%;
                bottom: 32px;
                z-index: -1;
            }

            +.history-plus-icon {
                background-color: $white;
                width: 68px;
                height: 56px;
            }

            &__inner {
                height: 100%;
            }

            &__image {
                overflow: hidden;

                img {
                    transition: transform .3s ease-in;
                }

            }

            &__body {
                padding: 63px 54px 51px 54px;
                height: 100%;

                @include media('<large') {
                    padding: 40px;
                }

                @include media('<769px') {
                    padding: 30px;
                }

                .year {
                    display: block;
                    color: $yellow;
                    font-size: rem-calc(35);
                    line-height: lh(30,35);
                    margin-bottom: 15px;
                    font-weight: 500;

                    @include media('<769px') {
                        font-size: rem-calc(25);
                        line-height: lh(30,25);
                        margin-bottom: 10px;
                    }
                }

                h3 {
                    font-size: rem-calc(20);
                    line-height: lh(25,25);
                    font-weight: 700;
                    margin-bottom: 29px;

                    @include media('<769px') {
                        margin-bottom: 10px;
                    }
                }

                p {
                    font-size: rem-calc(17);
                    line-height: lh(25,17);
                    margin-bottom: 0;
                }
            }

            &:hover {
                .process-slide__image {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .glide {
            &__arrows {
                position: absolute;
                bottom: -30px;
                left: 0;
                gap: 25px;
                display: flex;
                border-radius: 50px;

                @include media('<large') {
                    position: relative;
                    bottom: 0;
                }
            }

            &__arrow {
                position: relative;
                width: 68px;
                height: 54px;
                background-repeat: no-repeat;
                top: 0;
                transform: none;
                transition: background .2s ease-in;
                background-color: #525E73; //#D1E3EF;
                border-radius: 50px;
                box-shadow: none;
                border: 2px solid #525E73;

                &:hover {
                    background-color: $dark-blue;

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right-white.svg");
                    }
                }

                &::before {
                    content: "";
                    width: 10px;
                    height: 18px;
                    background-size: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    top: calc(50% + 1px);
                    transform: translateY(-50%);
                }

                &--left {
                    left: auto;
                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right-white.svg");
                        transform: scaleX(-1) translateY(-50%);
                        left: 28px;
                    }
                }

                &--right {
                    right: auto;

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right-white.svg");
                        right: 28px;
                    }
                }

                &--disabled {
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-left-disabled.svg");
                        transform: scaleX(1) translateY(-50%);
                        left: 28px;
                    }

                    &.glide__arrow--right {
                        &::before {
                            background-image: url("/assets/templates/images/svg/history-slider-arrow-left-disabled.svg");
                            transform: scaleX(-1) translateY(-50%);
                            left: 28px;
                        }
                    }

                    &.glide__arrow--left {
                        &:hover {

                            &::before {
                                transform: scaleX(-1) translateY(-50%);
                                left: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}
