@use "sass:map";

.rte-content {
    padding: 0 0 171px 0;

    @include media('<medium') {
        padding: 0 0 60px 0;
    }

    &__wrapper {
        max-width: 839px;

        h2 {
            font-size: rem-calc(18);
            line-height: lh(30,18);
            font-weight: 700;
            margin-bottom: 0;
        }

        p {
            font-size: rem-calc(18);
            line-height: lh(30,18);
            font-weight: 300;
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }

            + ul {
                margin-top: -30px;
                margin-bottom: 0;
                list-style: none;
                margin-left: 0;

                li {
                    &::before {
                        content: "•";
                        display: inline-block;
                        margin-right: 22px;
                    }
                }
            }
        }
    }
}
