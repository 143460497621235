@use "sass:map";

.product-category {
    padding-bottom: 153px;

    @include media('<medium') {
        padding-bottom: 60px;
    }

    &__wrapper {
        display: flex;
        gap: 66px;

        @include media('<large') {
            gap: 40px;
        }

        @include media('<medium') {
            flex-direction: column;
        }
    }

    &__sidebar {
        width: 323px;
        height: 100%;
        position: relative;

        /* sticky stuff */
        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        @include media('<medium') {
            width: 300px;
        }
    }

    &__content {
        flex: 1;
        width: calc(100% - 389px);

        @include media('<large') {
            width: calc(100% - 340px);
        }

        @include media('<medium') {
            width: 100%;
        }

        .product-list {
            h2 {
                font-size: rem-calc(40);
                line-height: lh(75,40);
                color: #D1E3EF;
                font-weight: 500;
                margin-bottom: 16px;

                @include media('<large') {
                    font-size: rem-calc(28);
                    line-height: lh(40,28);
                }
            }

            .product-item {
                background-color: $white;
                display: flex;
                margin-bottom: 61px;
                min-height: 325px;

                @include media('<large') {
                    min-height: auto;
                }

                @include media('<769px') {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &__image {
                    width: 267px;
                    border-right: 1px solid $lighter-blue;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include media('<large') {
                        width: 180px;
                        padding: 10px;
                    }

                    @include media('<769px') {
                        border-right: 0;
                        border-bottom: 1px solid $lighter-blue;
                        width: 100%;
                    }
                }

                &__body {
                    padding: 68px 55px 50px 56px;
                    flex: 1;

                    @include media('<large') {
                        padding: 30px;
                    }

                    h3 {
                        font-size: rem-calc(35);
                        line-height: lh(25,35);
                        margin-bottom: 32px;
                        color: $lighter-blue;

                        @include media('<large') {
                            font-size: rem-calc(20);
                            line-height: lh(25,20);
                            margin-bottom: 5px;
                        }
                    }

                    p {
                        margin-bottom: 0;

                        @include media('<large') {
                            font-size: rem-calc(16);
                            line-height: lh(25,16);
                        }
                    }
                }
            }
        }
    }

    .glide-product-slider {
        margin-bottom: 80px;

        @include media('<large') {
            margin-bottom: 40px;
        }

        .product-slide {
            &__image {
                height: 487px;

                @include media('<large') {
                    height: 300px;
                }

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }

            &__body {
                padding: 85px 83px 75px 83px;
                background-color: $white;

                @include media('<large') {
                    padding: 40px;
                }

                .subtitle {
                    font-size: rem-calc(30);
                    line-height: lh(25,30);
                    font-weight: 500;
                    color: #CCE1EE;
                    display: block;
                    margin-bottom: 7px;
                    text-transform: capitalize;

                    @include media('<large') {
                        font-size: rem-calc(25);
                        line-height: lh(25,25);
                        margin-bottom: 5px;
                    }
                }

                h3 {
                    font-size: rem-calc(45);
                    font-weight: 300;
                    line-height: lh(50,45);
                    margin-bottom: 25px;
                    @include line-clamp(1);

                    @include media('<large') {
                        font-size: rem-calc(30);
                        line-height: lh(44,30);
                        margin-bottom: 16px;
                    }
                }

                p {
                    margin-bottom: 47px;
                    //@include line-clamp(4);

                    @include media('<large') {
                        margin-bottom: 1rem;
                    }

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(27,14);
                    }
                }

                .btn-link {
                    font-size: rem-calc(17);

                    &::before,
                    &::after {
                        bottom: -3px;
                    }
                }
            }
        }
    }

    .glide {
        &__arrows {
            position: absolute;
            top: 406px;
            right: 25px;
            display: flex;
            background-color: rgba($dark-blue, .56);
            border-radius: 50px;

            @include media('<large') {
                top: 224px;
            }
        }

        &__arrow {
            position: relative;
            width: 48px;
            height: 56px;
            border: 0;
            background-repeat: no-repeat;
            top: 0;
            transform: none;
            transition: background .2s ease-in;

            &::before {
                content: "";
                width: 9px;
                height: 16px;
                background-size: 100%;
                position: absolute;
                background-repeat: no-repeat;
                top: 50%;
                transform: translateY(-50%);
            }

            &--left {
                left: auto;
                border-radius: 30px 0 0 30px;

                &:hover {
                    background: linear-gradient(270deg, rgba(27,41,74,0) 0%, rgba(27,41,74,1) 100%);
                }

                &::before {
                    background-image: url("/assets/templates/images/svg/slider-arrow-left.svg");
                    left: 25px;
                }
            }

            &--right {
                right: auto;
                border-radius: 0 30px 30px 0;

                &:hover {
                    background: linear-gradient(90deg, rgba(27,41,74,0) 0%, rgba(27,41,74,1) 100%);
                }

                &::before {
                    background-image: url("/assets/templates/images/svg/slider-arrow-left.svg");
                    transform: scaleX(-1) translateY(-50%);
                    right: 25px;
                }
            }
        }
    }
}

.product-navigation {
    max-width: 323px;

    @include media('<large') {
        max-width: 300px;
    }

    /* sticky stuff */
    position: sticky;
    top: 140px;
    width: 100%;
    margin-bottom: 0;

    ul.tabs {
        //max-width: 342px;
        background: $dark-blue;
        border-color: transparent;
        z-index: 2;
        position: relative;

        @include media('<large') {
            max-width: 300px;
        }

        li.tabs-title {
            margin-bottom: 1px;

            &.is-active,
            &:hover {
                a {
                    background-color: transparent;
                    padding: 32px 20px 22px 54px;
                    background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
                    background-repeat: no-repeat;
                    background-position: 37px center;
                    background-size: 6px;

                    @include media('<large') {
                        padding: 22px 10px 16px 68px;
                    }

                    @include media('<medium') {
                        padding: 15px 10px 15px 36px;
                        background-position: 20px center;
                    }
                }
            }

            a {
                color: $white;
                font-size: rem-calc(20);
                line-height: lh(25,20);
                font-weight: 400;
                padding: 32px 20px 22px 37px;
                border-top: 1px solid $black;
                transition: padding .25s ease-in;

                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $white;
                }

                @include media('<large') {
                    font-size: rem-calc(18);
                    line-height: lh(25,20);
                    padding: 22px 20px 16px 38px;
                }

                @include media('<medium') {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                    padding: 15px 20px 15px 20px;
                }
            }

            &:first-child {
                a {
                    border-top: 0;
                }
            }
        }
    }

    .heading {
        color: $white;
        font-size: rem-calc(25);
        line-height: lh(44,25);
        font-weight: 700;
        background-color: $darker-blue;
        padding: 35px 20px 33px 49px;

        @include media('<large') {
            max-width: 300px;
            font-size: rem-calc(20);
            line-height: lh(44,20);
        }

        @include media('<medium') {
            font-size: rem-calc(16);
            line-height: lh(25,16);
            padding: 15px 20px 15px 20px;
        }
    }

    a.back-to-overview {
        color: $white;
        font-size: rem-calc(20);
        line-height: lh(25,20);
        font-weight: 400;
        padding: 31px 20px 25px 39px;
        border-top: 1px solid $black;
        transition: padding .25s ease-in;
        display: block;
        background-color: $darker-blue;

        @include media('<large') {
            font-size: rem-calc(18);
            line-height: lh(25,20);
            padding: 22px 20px 16px 38px;
        }

        @include media('<medium') {
            font-size: rem-calc(16);
            line-height: lh(25,16);
            padding: 15px 20px 15px 20px;
            background-position: 20px center;
        }

        &:hover {
            padding: 31px 20px 25px 56px; //32px 20px 22px 54px;
            background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
            background-repeat: no-repeat;
            background-position: 37px center;
            background-size: 6px;

            @include media('<large') {
                padding: 22px 20px 16px 68px;
            }
            
            @include media('<medium') {
                padding: 15px 20px 15px 36px;
                background-position: 20px center;
            }
        }
    }
}
