@use "sass:map";

.used-machine-detail {
    padding: 9px 0 141px 0;

    @include media('<769px') {
        padding: 0 0 60px 0;
    }

    .glide-content-slider {
        margin: 0;

        .glide__arrows {
            background-color: rgba($dark-blue, .61);
            right: 31px;
        }
    }

    .product-info {
        max-width: 683px;

        &__text {
            background-color: $white;
            padding: 94px 94px 80px 83px;

            @include media('<large') {
                padding: 40px;
            }

            .subtitle {
                font-size: rem-calc(30);
                line-height: lh(25,30);
                font-weight: 500;
                color: #CCE1EE;
                display: block;
                margin-bottom: 13px;
                text-transform: initial;

                @include media('<medium') {
                    font-size: rem-calc(25);
                    line-height: lh(25,25);
                    margin-bottom: 5px;
                }
            }

            h2 {
                font-size: rem-calc(45);
                font-weight: 300;
                line-height: lh(50,45);
                margin-bottom: 24px;

                @include media('<medium') {
                    font-size: rem-calc(30);
                    line-height: lh(44,30);
                    margin-bottom: 28px;
                }
            }

            p {
                margin-bottom: 30px;

                @include media('<medium') {
                    margin-bottom: 1rem;
                }

                @include media('<medium') {
                    font-size: rem-calc(14);
                    line-height: lh(27,14);
                }

                &:last-of-type {
                    margin-bottom: 47px;

                    @include media('<medium') {
                        margin-bottom: 1rem;
                    }
                }
            }

            .btn-link {
                font-size: rem-calc(17);

                &::before,
                &::after {
                    bottom: -3px;
                }
            }
        }
    }

    .product-details {
        max-width: 462px;
        margin-left: auto;
        margin-top: 5px;

        @include media('<medium') {
            max-width: 100%;
            margin-top: 50px;
        }

        h3 {
            font-size: rem-calc(25);
            line-height: lh(25,25);
            font-weight: 700;
            color: $lighter-blue;
            padding-bottom: 33px;
            margin-bottom: 20px;
            border-bottom: 1px solid #D1E3EF;

            @include media('<medium') {
                padding-bottom: 15px;
                margin-bottom: 15px;
                font-size: rem-calc(20);
                line-height: lh(25,20);
            }
        }

        .list-wrapper {
            margin-top: 34px;

            ul {
                flex-direction: column;
                margin-left: -7px;

                li {
                    font-weight: 400;
                    font-size: rem-calc(17);
                    line-height: lh(25,17);
                    margin-right: 0;
                    margin-bottom: 3px;
                    letter-spacing: -0.15px;

                    &::before {
                        top: 6px;
                        left: 2px;
                    }
                }
            }
        }
    }
}

/* DivTable.com */
.divTable{
    display: table;
    width: 100%;
    margin-bottom: 100px;
    margin-top: 37px;

    @include media('<769px') {
        margin-bottom: 30px;
        margin-top: 30px;
    }
}
.divTableRow {
    display: flex; //table-row;

    @include media('<1280px') {
        display: table-row;
    }
}
.divTableCell, .divTableHead {
    display: table-cell;
    padding: 0; //3px 10px;
}
.divTableCell {
    font-size: rem-calc(17);
    line-height: rem-calc(25,17);
    font-weight: 400;
    padding-bottom: 6.5px;

    @include media('<medium') {
        font-size: rem-calc(15);
        line-height: rem-calc(25,15);
    }

    &:nth-child(1) {
        width: 269px;
        padding-right: 20px;

        @include media('<medium') {
            padding-right: 10px;
            width: auto;
        }
    }

    &:nth-child(2) {
        font-weight: 300;
        width: 109px;
        padding-right: 10px;

        @include media('<medium') {
            width: auto;
        }
    }

    &:nth-child(3) {
        font-weight: 300;
    }
}
.divTableHeading {
    display: table-header-group;
    font-size: rem-calc(20);
    font-weight: 500;
    line-height: lh(25,20);

    .divTableCell {
        font-size: rem-calc(20);
        line-height: lh(25,20);
        font-weight: 500;
        padding-bottom: 18px;

        @include media('<1280px') {
            font-size: rem-calc(15);
            line-height: lh(25,15);
        }
    }
}
.divTableHeading {
    display: flex; //table-header-group;
    font-weight: bold;

    @include media('<1280px') {
        display: table-header-group;
    }
}
.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}
.divTableBody {
    display: table-row-group;
}
