@use "sass:map";

.breadcrumbs {
    margin: 0 0 10px 0;

    li {
        color: $lighter-blue;
        font-size: rem-calc(14);
        font-weight: 400;
        text-transform: capitalize;

        @include media('<medium') {
            font-size: rem-calc(12);
        }

        &:not(:last-child)::after {
            content: "";
            width: 4px;
            height: 7px;
            background-image: url("/assets/templates/images/svg/icon-breadcrumb.svg");
            background-repeat: no-repeat;
            display: inline-block;
            border-radius: 14px;
            margin: 0 5px 3px 6px;
            position: relative;
            top: 2px;
        }

        a {
            color: rgba(var(--color-primary-rgb), .75);
            font-weight: 400;

            @include media('<medium') {
                font-size: rem-calc(12) !important;
            }
        }
    }
}
