@use "sass:map";

.news-overview {
    padding: 6px 0 144px 0;

    @include media('<medium') {
        padding: 0 0 60px 0;
    }

    &__wrapper {
        display: flex;
        gap: 64px;
        flex-wrap: wrap;

        @include media('<large') {
            gap: 40px;
        }

        .news-item {
            width: calc(50% - 32px);

            @include media('<large') {
                width: calc(50% - 20px);
            }

            @include media('<medium') {
                width: 100%;
            }

            a {
                height: 100%;
                display: flex;
                flex-direction: column;

                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            &__image {
                overflow: hidden;

                @include media('<medium') {
                    height: 244px;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                img {
                    transition: transform .2s ease-out;
                }
            }

            &__body {
                padding: 56px 56px 58px 56px;
                background-color: $white;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                min-height: 471px;

                @include media('<large') {
                    padding: 40px;
                }

                @include media('<medium') {
                    padding: 20px;
                    min-height: initial;
                }

                .tag {
                    background-color: $bg-color;
                    font-size: rem-calc(20);
                    line-height: lh(25,20);
                    padding: 17px 29px 11px 25px;
                    font-weight: 500;
                    display: inline-block;
                    margin-bottom: 28px;

                    @include media('<medium') {
                        font-size: rem-calc(15);
                        line-height: lh(25,15);
                        padding: 13px 25px 8px 25px;
                        margin-bottom: 30px;
                    }

                    &::before {
                        content: "#";
                        color: #CCE1EE;
                        margin-right: 5px;
                    }
                }

                h3 {
                    font-size: rem-calc(35);
                    line-height: lh(45,35);
                    margin-bottom: 16px;
                    font-weight: 300;
                    max-width: 483px;

                    @include media('<medium') {
                        font-size: rem-calc(25);
                        line-height: lh(30,25);
                        margin-bottom: 19px;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                p {
                    max-width: 483px;
                    margin-bottom: 22px;

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(30,14);
                    }
                }

                .btn-link {
                    font-size: rem-calc(17);
                    margin-top: auto;

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(30,14);
                    }

                    &::before,
                    &::after {
                        bottom: -3px;
                    }
                }
            }
        }
    }
}
