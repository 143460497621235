$sidespace: calc((100vw - 1252px) / 2);

body {
    &.page-totaaloplossingen,
    &.page-productoverview,
    &.page-home,
    &.page-zaagmachines,
    &.page-actualiteiten,
    &.page-actualiteiten-detail,
    &.page-gebruikte-machines,
    &.page-gebruikte-machines-detail,
    &.page-content-page,
    &.page-privacy-cookie,
    &.page-algemene-voorwaarden,
    &.page-vacatures {
        background-color: $bg-color;
    }

    &.page-totaaloplossingen {
        flex-direction: row;
        flex-wrap: wrap;

        .header,
        .footer {
            flex: 1 1 100%;
        }

        main {
            flex: 1 1 100%;
            transition: flex .3s ease-in;
        }

        aside {
            flex: 1 1 621px; //1 1 544px;

            @include media('<1281px') {
                flex: 1 1 500px;
            }

            @include media('<1168px') {
                flex: 1 1 400px;
            }

        }

        .breadcrumbs {
            margin: 0 0 17px 0;
        }

        &.shrink {
            main {
                //flex: 1 1 calc(100% - 621px);

                @include media('>1324px') {
                    //padding-left: $sidespace;
                }

                @include media('<1281px') {
                    flex: 1 1 calc(100% - 500px);
                }

                @include media('<1168px') {
                    flex: 1 1 calc(100% - 400px);
                }

                @include media('<large') {
                    flex: 1 1 auto;
                }
            }
            @include media('>1324px') {
                .breadcrumb-and-title,
                .image-with-info-points {
                    //padding-left: $sidespace;
                    > .row > .columns {
                        //max-width: calc(100vw - 871px);
                        //max-width: calc(100vw - calc(100vw - 636px));
                        max-width: calc(100vw - ((100vw - 1263px) / 2) - 660px);
                    }
                }
            }
        }

        .cta-with-form.dark-title {
            padding: 150px 0 149px 0;

            @include media('<medium') {
                padding: 60px 0 60px 0;
            }
        }
    }

    &.page-over-hoffmann {
        .text-block-with-image-right {
            padding: 179px 0 169px 0;

            @include media('<large') {
                padding: 120px 0 120px 0;
            }

            @include media('<769px') {
                padding: 60px 0 60px 0;
            }
        }
    }

    &.page-werkwijze {
        .text-block-with-image-right {
            background-color: $bg-color;
            padding: 401px 0 60px 0;

            @include media('<769px') {
                padding: 300px 0 0 0;
            }

            .parallax-image {
                height: 478px;
            }
        }

        .cta-with-form {
            padding: 62px 0 165px 0;

            @include media('<769px') {
                padding: 60px 0;
            }
        }
    }

    &.page-actualiteiten-detail {
        .breadcrumbs {
            margin: 0 0 17px 0;
        }

        .breadcrumb-and-title {
            padding: 248px 0 60px 0;

            @include media('<large') {
                padding: 150px 0 60px 0;
            }

            @include media('<medium') {
                padding: 150px 0 30px 0;
            }
        }

        h1 {
            max-width: 490px;
        }

        .cta-block {
            max-width: 404px;
            margin-left: -8px;
            position: sticky;
            top: 140px;

            .cta-block__inner {
                padding: 52px 50px 33px 53px;

                @include media('<1200px') {
                    padding: 40px 25px 40px 40px;
                }

                @include media('<769px') {
                    padding: 38px 25px 44px 40px;
                }

                h2 {
                    margin-bottom: 57px;
                }

                .cta-block__details {
                    margin-left: -3px;

                    div {
                        margin-top: -2px;
                        margin-left: 22px;
                    }
                }
            }
        }
    }

    &.page-gebruikte-machines,
    &.page-gebruikte-machines-detail,
    &.page-privacy-cookie,
    &.page-productoverview {
        .breadcrumbs {
            margin: 0 0 17px 0;
        }
    }

    &.page-vacatures {
        .breadcrumbs {
            margin: 0 0 17px 0;
        }
    }

    &.page-contact {
        .breadcrumbs {
            margin: 0 0 8px 0;

            @include media('<769px') {
                margin: 0 0 13px 0;
            }
        }
    }

    &.page-gebruikte-machines-detail {
        .cta-with-form {
            padding: 141px 0 144px 0;

            @include media('<769px') {
                padding: 60px 0 60px 0;
            }
        }
    }

    &.page-content-page {
        .text-block-with-image-right {
            padding: 143px 0 135px 0;

            @include media('<769px') {
                padding: 60px 0 60px 0;
            }

            .subtitle {
                display: none;
            }
        }

        .cta-with-form {
            padding: 135px 0 132px 0;

            @include media('<769px') {
                padding: 60px 0 60px 0;
            }
        }
    }

    &.page-privacy-cookie {
        .breadcrumb-and-title {
            padding-bottom: 36px;
        }
    }

    &.page-afspraak-maken {
        .contact-form-with-sidebar .contact-sidebar__body {
            padding: 369px 65px 52px 65px;

            @include media('<large') {
                padding: 369px 40px 40px 40px;
            }

            @include media('<medium') {
                padding: 369px 20px 40px 20px;
            }
        }
    }

    &.page-gebruikte-machines {
        .breadcrumb-and-title.with-intro h1 {
            margin-bottom: 46px;
        }
    }

    &.page-algemene-voorwaarden {
        .header-with-text-block .text-block {
            top: auto;
        }
    }

    &.page-vacature-detail {
        .header-with-text-block .text-block {
            top: auto;
            bottom: -190px;
        }
    }
}
