@use "sass:map";
/**
Add the global SCSS/CSS here that should be available throughout the website
 */

:root {
    @each $name, $value in $root {
        --root-#{$name}: #{$value};
    }
}

html {
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: var(--root-font-family);
    font-weight: 300;
    font-size: rem-calc(18);
    line-height: lh(30,18);
    main {
        flex-grow: 1;
    }
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-family: var(--root-heading-font-family);
    font-weight: 700;
}

p, a {
    font-family: var(--root-font-family);
    font-weight: 300;
    line-height: lh(30,18);

    @include media('<medium') {
        font-size: rem-calc(15);
        //line-height: lh(30,15) !important;
    }
}

button {
    cursor: pointer;
}

$content-page-margin-bottom: (desktop: 140px, tablet: 70px, mobile: 45px);

main.content-page {
    section:last-of-type {
        margin-bottom: map.get($content-page-margin-bottom, desktop);

        //@include breakpoint(medium down) {
        @include media('<large') {
            margin-bottom: map.get($content-page-margin-bottom, tablet);
        }

        //@include breakpoint(small down) {
        @include media('<medium') {
            margin-bottom: map.get($content-page-margin-bottom, mobile);
        }
    }
}

.transition-fade {
    transition: 0.4s;
    opacity: 1;
}

html.is-animating .transition-fade {
    opacity: 0;
}

ul, ol, dl {
    line-height: lh(30,18);
}

.form-group {
    margin-bottom: 19px;

    @include media('<medium') {
        margin-bottom: 21px;
    }
    
    label {
        font-size: rem-calc(18);

        @include media('<medium') {
            font-size: rem-calc(14);
            font-weight: 400;
            margin-bottom: 3px;
        }
    }

    [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea {
        border-color: $input-border;
        box-shadow: none;
        height: 50px;
        padding: 18px 10px 10px 15px;
        font-size: rem-calc(18);
        font-weight: 300;
        margin: 0;
        background-color: $white;

        @include media('<medium') {
            font-size: rem-calc(16);
            height: 42px;
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #D1E3EF;
            opacity: 1; /* Firefox */
            font-weight: 400;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #D1E3EF;
            font-weight: 400;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #D1E3EF;
            font-weight: 400;
        }
    }

    #date {
        background-image: url("/assets/templates/images/svg/icon-calendar.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 20px) center;
    }

    textarea {
        min-height: 150px;

        @include media('<medium') {
            min-height: 125px;
        }
    }

    .form-control--description {
        display: none;
    }
}

.form-pagination {
    display: flex;
    button[type="submit"] {
        margin-left: auto;
        margin-top: 31px;
        padding: 20px 36px 17px 32px;

        @include media('<medium') {
            font-size: rem-calc(15);
            margin-top: 4px;
            padding: 17px 32px 13px 29px;
        }
    }
}
