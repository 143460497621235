@use "sass:map";

.header-with-text-block {
    width: 100%;
    height: 891px;
    //overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @include media('<1025px') {
        height: 700px;
    }

    @include media('<769px') {
        height: 500px;
    }

    &.with-button {
        margin-bottom: 413px;
        height: 665px;

        @include media('<large') {
            margin-bottom: 300px;
        }

        .text-block {
            width: 100%;
            //top: 453px;
            max-width: 816px;
            padding: 70px 32px 86px 68px;
            bottom: -305px;

            @include media('<large') {
                padding: 40px;
                top: auto;
                bottom: -200px;
                position: relative;
            }

            @include media('<medium') {
                padding: 40px;
                top: auto;
                bottom: -200px;
                position: relative;
            }

            .btn {
                margin-top: 34px;
                margin-left: 2px;
                padding: 13px 38px 13px 36px;
            }
        }

        &::before {
            background: linear-gradient(0deg, rgba(27,41,74,0.81) 0%, rgba(27,41,74,1) 100%);
        }
    }

    > .row {
        height: 100%;

        .columns {
            z-index: 1;
            display: flex;
            align-items: flex-end;
        }
    }

    &::before {
        content: "";
        background: linear-gradient(0deg, rgba(20,30,55,0.2) 0%, rgba(20,30,55,1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .text-block {
        padding: 70px 32px 66px 68px;
        background-color: #fff;
        position: absolute;
        //bottom: -190px;
        width: auto;
        top: 595px;
        max-width: 816px;

        @include media('<large') {
            padding: 40px;
            top: auto;
            bottom: -200px;
            position: relative;
        }

        @include media('<medium') {
            padding: 20px;
            top: auto;
            bottom: -150px;
            position: relative;
        }

        .breadcrumbs {
            margin-bottom: 7px;
        }

        h1 {
            font-size: rem-calc(50);
            line-height: lh(75,50);
            margin-bottom: 35px;
            font-weight: 700;

            @include media('<medium') {
                font-size: rem-calc(30);
                line-height: lh(35,30);
                margin-bottom: 1rem;
            }
        }

        p {
            font-size: rem-calc(18);
            line-height: lh(30,18);
            margin-bottom: 10px;
            margin-left: 3px;
            max-width: 676px;

            @include media('<medium') {
                font-size: rem-calc(16);
                line-height: lh(30,16);
            }
        }

        .list-wrapper {
            margin: 67px auto 0 auto;

            @include media('<769px') {
                margin-top: 30px;
            }

            ul {
                li {
                    margin-right: 41px;

                    @include media('<769px') {
                        margin-right: 18px;
                    }
                }
            }
        }
    }

    &.parallax {
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;

        @include media('<1920px') {
            background-size: cover;
            background-position: center;
        }

        @supports (-webkit-touch-callout: none) {
            background-attachment: unset;
        }
    }

    &.vacancy-detail {
        .text-block {
            padding: 76px 32px 63px 68px;


            @include media('<large') {
                padding: 40px;
            }

            @include media('<medium') {
                padding: 40px;
            }

            .breadcrumbs {
                margin-bottom: 14px;
            }

            h1 {
                line-height: lh(60,50);
                margin-bottom: 41px;
            }

            p {
                margin-bottom: 30px;
                margin-left: 0;
            }

            .vacancy-details {
                display: flex;
                margin-top: 42px;
                margin-left: 25px;
                gap: 120px;

                @include media('<medium') {
                    flex-direction: column;
                    margin-left: 0;
                    gap: 20px;
                }

                &__item {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                    font-weight: 300;

                    b {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
