@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

@import 'variables';
@import 'functions';
@import 'fonts';
@import 'breakpoints';
@import '~include-media/dist/include-media';
@import "global";
@import "buttons";
@import 'aos/dist/aos.css';
@import 'iframe-lightbox/css/iframe-lightbox.css';

// GlideJS
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

// Flatpickr
@import "flatpickr/dist/flatpickr.css";

//Pages
@import "pages/pages";

//Components
@import '../components/header/header';
@import '../components/footer/footer';
@import '../components/breadcrumb/breadcrumb';
//@import '../components/slider/slider';
//@import '../components/popup/popup';
//@import '../components/card/card';
//@import '../components/video/video';
//@import '../components/card-overview/filters/filters';
//@import '../components/accordion/accordion';
@import '../components/header-with-submenus/header-with-submenus';
@import '../components/rte-content/rte-content';
@import '../components/homepage-header/homepage-header';
@import '../components/text-with-cta/text-with-cta';

@import '../components/text-block-with-list/text-block-with-list';

@import '../components/expertise-tabs/expertise-tabs';
@import '../components/process-bullets/process-bullets';
@import '../components/news-slider/news-slider';

@import '../components/breadcrumb-and-title/breadcrumb-and-title';
@import '../components/product-category-overview/product-category-overview';
@import '../components/text-block-with-image-right/text-block-with-image-right';
@import '../components/cta-with-form/cta-with-form';
@import '../components/product-category/product-category';


@import '../components/image-with-info-points/image-with-info-points';

@import '../components/video-header/video-header';

@import '../components/parallax-image-with-quote/parallax-image-with-quote';
@import '../components/about-intro-with-sticky-nav/about-intro-with-sticky-nav';
@import '../components/history-slider/history-slider';
@import '../components/our-people/our-people';
@import '../components/header-with-text-block/header-with-text-block';
@import '../components/process-slider/process-slider';
@import '../components/news-overview/news-overview';

@import '../components/news-detail-content/news-detail-content';
@import '../components/product-overview-with-filters/product-overview-with-filters';
@import '../components/used-machine-detail/used-machine-detail';
@import '../components/rte-with-sidebar/rte-with-sidebar';

@import '../components/contact-header/contact-header';
@import '../components/contact-form-with-sidebar/contact-form-with-sidebar';

@import '../components/vacancies-header/vacancies-header';
@import '../components/vacancies-overview/vacancies-overview';

@import '../components/vacancy-detail-tabs/vacancy-detail-tabs';