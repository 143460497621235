@use "sass:map";

.news-detail-content {
    padding: 0 0 69px 0;

    &__wrapper {
        max-width: 706px;

        .content {
            background-color: $white;
            padding: 70px 74px 65px 71px;
            margin-bottom: 73px;

            @include media('<medium') {
                padding: 40px;
            }
        }

        .tag {
            background-color: $bg-color;
            font-size: rem-calc(20);
            line-height: lh(25,20);
            padding: 17px 29px 11px 25px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 23px;

            @include media('<medium') {
                font-size: rem-calc(15);
                line-height: lh(25,15);
                padding: 13px 25px 8px 25px;
                margin-bottom: 30px;
            }

            &::before {
                content: "#";
                color: #CCE1EE;
                margin-right: 5px;
            }

            + p {
                max-width: 540px;
            }
        }

        .subtitle {
            font-size: rem-calc(30);
            line-height: lh(25,30);
            font-weight: 500;
            color: #CCE1EE;
            display: block;
            margin-bottom: 10px;
            text-transform: initial;
            margin-top: 73px;

            @include media('<medium') {
                font-size: rem-calc(25);
                line-height: lh(25,25);
                margin-bottom: 5px;
                margin-top: 30px;
            }
        }

        h2 {
            font-size: rem-calc(40);
            line-height: lh(50,40);
            margin-bottom: 62px;
            font-weight: 500;

            @include media('<medium') {
                font-size: rem-calc(30);
                line-height: lh(40,30);
                margin-bottom: 20px;
            }
        }

        h3 {
            font-size: rem-calc(20);
            line-height: lh(25,20);
            margin-bottom: 15px;
            font-weight: 700;
            margin-top: 74px;

            @include media('<medium') {
                margin-top: 50px;
            }
        }

        p {
            font-size: rem-calc(18);
            line-height: lh(30,18);
            margin-bottom: 30px;

            @include media('<medium') {
                margin-bottom: 15px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .sidebar-column {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

    h4 {
        font-size: rem-calc(20);
        line-height: lh(25,20);
        margin-bottom: 27px;
    }

    .read-more {

        @include media('<large') {
            margin-bottom: 50px;
        }

        &__wrapper {
            display: flex;
            gap: 36px;

            @include media('<large') {
                gap: 20px;
            }
        }

        &__item {
            flex: 1;

            &-image {
                overflow: hidden;
                height: 180px;

                @include media('<medium') {
                    height: 120px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: transform .2s ease-in;
                }
            }

            &-body {
                background-color: $white;
                padding: 38px 39px 39px 39px;
                margin-bottom: 31px;

                @include media('<large') {
                    padding: 20px;
                }

                h2 {
                    font-size: rem-calc(20);
                    line-height: lh(30,20);
                    margin-bottom: 12px;

                    @include media('<medium') {
                        font-size: rem-calc(16);
                        line-height: lh(25,16);
                        @include line-clamp(2);
                    }
                }

                p {
                    font-size: rem-calc(18);
                    line-height: lh(30,18);
                    margin-bottom: 0;

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(25,14);
                    }
                }
            }

            .pagination-button {
                font-size: rem-calc(17);
                line-height: lh(25,17);
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 31px;
                color: $black;

                @include media('<medium') {
                    font-size: rem-calc(14);
                    line-height: lh(25,14);
                    gap: 10px;
                    text-indent: -1000vw;
                }

                span {
                    &.arrow {
                        width: 68px;
                        height: 56px;
                        background-color: #D1E3EF;
                        border-radius: 50px;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: background-color .2s ease-in;

                        @include media('<medium') {
                            width: 44px;
                            height: 33px;
                            background-size: 7px;
                        }
                    }
                }

                &.prev-item {
                    span {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right.svg");
                        transform: scaleX(-1);
                    }
                }

                &.next-item {
                    flex-direction: row-reverse;
                    float: right;

                    span {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right.svg");
                    }
                }
            }

            &:hover {
                .read-more__item-image {
                    img {
                        transform: scale(1.1);
                    }
                }

                .pagination-button {
                    span {
                        background-color: $dark-blue;
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right-white.svg");
                    }
                }
            }
        }
    }
}

.glide-content-slider {
    margin-top: 65px;
    margin-bottom: 73px;
    margin-left: 3px;

    @include media('<medium') {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .glide {
        &__arrows {
            position: absolute;
            bottom: 31px;
            right: 35px;
            display: flex;
            background-color: rgba($bg-color, .61);
            border-radius: 50px;
        }

        &__arrow {
            position: relative;
            width: 48px;
            height: 56px;
            border: 0;
            background-repeat: no-repeat;
            top: 0;
            transform: none;
            transition: background .2s ease-in;

            &::before {
                content: "";
                width: 9px;
                height: 16px;
                background-size: 100%;
                position: absolute;
                background-repeat: no-repeat;
                top: 50%;
                transform: translateY(-50%);
            }

            &--left {
                left: auto;
                border-radius: 30px 0 0 30px;

                &:hover {
                    background: linear-gradient(270deg, rgba(27,41,74,0) 0%, rgba(27,41,74,1) 100%);
                }

                &::before {
                    background-image: url("/assets/templates/images/svg/slider-arrow-left.svg");
                    left: 25px;
                }
            }

            &--right {
                right: auto;
                border-radius: 0 30px 30px 0;

                &:hover {
                    background: linear-gradient(90deg, rgba(27,41,74,0) 0%, rgba(27,41,74,1) 100%);
                }

                &::before {
                    background-image: url("/assets/templates/images/svg/slider-arrow-left.svg");
                    transform: scaleX(-1) translateY(-50%);
                    right: 25px;
                }
            }
        }
    }
}
