@use "sass:map";

.product-overview-with-filters {
    padding: 29px 0 140px 0;

    @include media('<medium') {
        padding: 20px 0 60px 0;
    }

    .product-overview {
        display: flex;
        gap: 117px;

        @include media('<1280px') {
            gap: 40px;
        }

        @include media('<medium') {
            flex-direction: column;
        }

        &__sidebar {
            width: 216px;
            display: flex;
            align-items: flex-start;

            > div {
                position: sticky;
                top: 140px;
                width: 100%;
                margin-bottom: 0;
            }

            h4 {
                font-size: rem-calc(20);
                line-height: lh(25,20);
                margin-bottom: 24px;
                padding-bottom: 16px;
                color: $lighter-blue;
                border-bottom: 1px solid #D1E3EF;
                font-weight: 500;
                margin-top: 2px;
            }
        }

        &__items {
            flex: 1;

            &-inner {
                //width: calc(100% - 216px);
                display: flex;
                gap: 80px;
                flex-wrap: wrap;

                @include media('<1280px') {
                    gap: 40px;
                }


                @include media('<large') {
                    gap: 20px;
                }

                @include media('<medium') {
                    gap: 20px;
                }

                .product-item {
                    background-color: $white;
                    flex: 1 1 auto;
                    max-width: calc(50% - 40px);

                    @include media('<1280px') {
                        max-width: calc(50% - 20px);
                    }

                    @include media('<large') {
                        max-width: calc(50% - 20px);
                        //max-width: 100%;
                    }

                    @include media('<500px') {
                        max-width: 100%;
                    }

                    a {
                        &:hover {
                            .btn-link {
                                &::after {
                                    width: 100%;
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &__image {
                        height: 262px;
                        border-bottom: 1px solid $bg-color;
                        //display: flex;
                        //align-items: center;
                        //justify-content: center;
                        padding: 20px;

                        img {
                            margin: 0 auto;
                            display: block;
                            width: auto;
                            height: 100%;
                        }

                        @include media('<large') {
                            height: 180px;
                            padding: 20px;
                        }

                        @include media('<medium') {
                            height: 150px;
                            padding: 10px;
                        }
                    }

                    &__body {
                        padding: 45px 43px 38px 43px;

                        @include media('<large') {
                            padding: 30px;
                        }

                        @include media('<medium') {
                            padding: 20px;
                        }

                        .subtitle {
                            color: #CCE1EE;
                            font-size: rem-calc(20);
                            line-height: lh(25,20);
                            margin-bottom: 6px;
                            font-weight: 500;
                            display: block;
                        }

                        h2 {
                            font-size: rem-calc(30);
                            line-height: lh(44,30);
                            margin-bottom: 31px;
                            font-weight: 300;

                            @include media('<large') {
                                font-size: rem-calc(20);
                                line-height: lh(25,20);
                                margin-bottom: 20px;
                                word-break: break-word;
                            }
                        }

                        .btn-link {
                            font-size: rem-calc(17);
                            font-weight: 300;

                            &::before,
                            &::after {
                                bottom: -1px;
                            }
                        }
                    }
                }
            }

            button {
                display: block;
                line-height: inherit;
                margin: 80px auto 0 auto;
                padding: 13px 33px 13px 33px;

                @include media('<medium') {
                    margin: 60px auto 0 auto;
                }
            }
        }
    }

    // Custom Checkbox
    .checkbox {
        padding: 0;
        margin-bottom: 5px;

        label {
            transition: 0.3s;
        }
        &:hover {
            .custom-checkbox + label::before {
                border-color: $bg-color;
                background-color: $dark-blue;
            }
            label {
                color: red;
            }
        }
        .custom-checkbox {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        /* for the label element associated with .custom-checkbox */
        .custom-checkbox + label {
            display: inline-flex;
            align-items: center;
            user-select: none;
            font-size: rem-calc(20);
            line-height: lh(25,20);
            color: $dark-blue;
            margin: 0;

            @include media('<large') {
                font-size: rem-calc(18);
                line-height: lh(25,18);
            }
        }
        /* creating a pseudo-element in label before with the following styles*/
        .custom-checkbox + label::before {
            transition: 0.3s;
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #D1E3EF;
            border-radius: 0;
            margin-right: 25px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: $white;
            top: -3px;
            position: relative;
        }
        /* styles for a checkbox in the checked state */
        .custom-checkbox:checked + label::before {
            border-color: $bg-color;
            background-color: $dark-blue;
        }
    }
}
