@use "sass:map";

.vacancy-detail-tabs {
    padding: 337px 0 60px 0;
    background-color: $bg-color;

    @include media('<large') {
        padding: 300px 0 60px 0;
    }
    
    .tabs-wrapper {
        display: flex;
        align-items: flex-start;

        @include media('<medium') {
            flex-direction: column;
        }
    }

    ul.tabs {
        width: 405px;
        background: rgba($dark-blue, .92);
        border-color: transparent;
        z-index: 2;
        position: relative;

        @include media('<large') {
            width: 300px;
        }

        @include media('<medium') {
            width: 100%;
        }

        li.tabs-title {
            margin-bottom: 1px;

            &.is-active,
            &:hover {
                a {
                    background-color: transparent;
                    padding: 32px 20px 22px 67px;
                    background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
                    background-repeat: no-repeat;
                    background-position: 47px center;
                    background-size: 6px;

                    @include media('<large') {
                        padding: 20px 20px 16px 40px;
                        background-position: 20px center;
                    }
                }
            }

            a {
                color: $white;
                font-size: rem-calc(20);
                line-height: lh(25,20);
                font-weight: 400;
                padding: 32px 20px 22px 47px;
                border-top: 1px solid $black;
                transition: padding .25s ease-in;

                @include media('<large') {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                    padding: 20px 20px 16px 20px;
                }
            }

            &:first-child {
                a {
                    border-top: 0;
                }
            }
        }
    }

    .tabs-content {
        background: $white;
        flex: 1;
        border: 0;

        .tabs-panel {
            padding: 90px 80px 52px 82px;

            @include media('<large') {
                padding: 40px;
            }

            @include media('<medium') {
                padding: 20px;
            }

            .subtitle {
                font-size: rem-calc(30);
                line-height: lh(25,30);
                color: #CCE1EE;
                margin-bottom: 17px;
                display: block;
                font-weight: 500;

                @include media('<large') {
                    font-size: rem-calc(20);
                    line-height: lh(25,20);
                }
            }

            h2 {
                font-size: rem-calc(45);
                line-height: lh(44,45);
                margin-bottom: 47px;
                font-weight: 300;

                @include media('<large') {
                    font-size: rem-calc(30);
                    line-height: lh(30,30);
                    margin-bottom: 20px;
                }
            }

            p {
                @include media('<medium') {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                }
                
                + ul {
                    margin-top: -9px;
                }
            }

            ul {
                list-style: none;
                display: flex;
                margin-left: 0;
                flex-direction: column;
                margin-bottom: 1rem;

                @include media('<medium') {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

                li {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                    font-weight: 300;
                    padding-left: 22px;
                    position: relative;
                    margin-bottom: 9px;

                    &::before {
                        content: "";
                        background-image: url("/assets/templates/images/svg/icon-check.svg");
                        position: absolute;
                        width: 14px;
                        height: 12px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        top: 7px;
                        left: -1px;
                    }
                }
            }
        }
    }
}
