@use "sass:map";

.news-slider {
    background-color: $white;
    padding: 191px 0 168px 0;

    @include media('<large') {
        padding: 96px 0 116px 0;
    }

    @include media('<medium') {
        padding: 96px 0 116px 0;
    }

    .row.expanded {
        .columns {
            padding-left: 0;
            padding-right: 0;

            @include media('<medium') {
                padding-left: 40px;
            }
        }
    }

    h2 {
        color: $yellow;
        font-size: rem-calc(50);
        line-height: lh(75,50);
        margin-bottom: 41px;
        font-weight: 500;

        @include media('<medium') {
            font-size: rem-calc(40);
            line-height: lh(75,40);
            margin-bottom: 29px;
        }
    }

    &__wrapper {
        padding-left: calc((100vw - 1235px) / 2);
        padding-right: 0;
        margin-bottom: 28px;

        @include media('<1260px') {
            padding-left: 20px;
        }

        @include media('<medium') {
            padding-left: 0;
        }

        .swiper-wrapper {
            //gap: 64px;
            @include media('<medium') {
                margin-bottom: 30px;
            }
        }

        .swiper-slide {
            width: 100%;
            //max-width: 578px;

            a {
                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            &__image {
                overflow: hidden;

                @include media('<medium') {
                    height: 244px;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                img {
                    transition: transform .2s ease-out;
                }
            }

            &__body {
                padding: 56px 56px 83px 0;
                background-color: $white;

                @include media('<medium') {
                    padding: 30px 0 0 0;
                }

                .tag {
                    background-color: $bg-color;
                    font-size: rem-calc(20);
                    line-height: lh(25,20);
                    padding: 17px 49px 11px 25px;
                    font-weight: 500;
                    display: inline-block;
                    margin-bottom: 34px;

                    @include media('<medium') {
                        font-size: rem-calc(15);
                        line-height: lh(25,15);
                        padding: 13px 25px 8px 25px;
                        margin-bottom: 30px;
                    }

                    &::before {
                        content: "#";
                        color: #CCE1EE;
                        margin-right: 5px;
                    }
                }

                h3 {
                    font-size: rem-calc(35);
                    line-height: lh(45,35);
                    margin-bottom: 35px;
                    font-weight: 300;
                    max-width: 483px;

                    @include media('<medium') {
                        font-size: rem-calc(30);
                        line-height: lh(40,30);
                        margin-bottom: 19px;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                p {
                    max-width: 483px;
                    margin-bottom: 0;

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(30,14);
                    }
                }
            }
        }
    }
}

/* Scrollbar */
.swiper-scrollbar {
    border-radius: 0;
    position: relative;
    -ms-touch-action: none;
    background: $bg-color;
    .swiper-scrollbar-disabled > &,
    &.swiper-scrollbar-disabled {
        display: none !important;
    }
    .swiper-horizontal > &,
    &.swiper-scrollbar-horizontal {
        position: absolute;
        left: 0;
        bottom: 3px;
        z-index: 50;
        height: 2px;
        width: 98%;
        max-width: 1220px;

        @include media('<medium') {
            bottom: 6px;
            max-width: 304px;
        }
    }
    .swiper-vertical > &,
    &.swiper-scrollbar-vertical {
        position: absolute;
        right: 3px;
        top: 1%;
        z-index: 50;
        width: 2px;
        height: 98%;
    }
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: $dark-blue;
    border-radius: 10px;
    left: 0;
    top: 0;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}
.swiper-scrollbar-lock {
    display: none;
}
