$button-hover-background-color: #006B3E;
$button-background-color: #50AB41;

.btn {
    background-color: $yellow;
    color: $white;
    font-weight: 400;
    border-radius: 50px;
    display: inline-block;
    padding: 13px 37px 13px 37px;
    position: relative;
    z-index: 0;
    border: 0;

    @include media('<medium') {
        padding: 10px 20px 10px 20px;
        font-size: rem-calc(15);
        text-align: center;
        line-height: 1.2;
    }

    &::after {
        content: "";
        background-color: $yellow-dark;
        width: 56px;
        height: 100%;
        border-radius: 50px;
        position: absolute;
        top: 0;
        left: 0;
        transition: width .2s ease-in, opacity .1s ease-in;
        z-index: -1;
        opacity: 0;
    }

    &:hover,
    &:focus {
        color: $white;

        &::after {
            width: 100%;
            opacity: 1;
        }
    }

    &-lightblue {
        background-color: $light-blue;

        &::after {
            background-color: $light-blue2;
        }
    }

    &-lighterblue {
        background-color: $lighter-blue;

        &::after {
            background-color: $darker-blue;
        }
    }

    &-icon {
        padding: 13px 37px 13px 74px;

        &.plus {
            background-image: url("/assets/templates/images/svg/icon-plus-yellow.svg");
            background-repeat: no-repeat;
            background-position: 43px center;
        }
    }

    &-transparent {
        background-color: rgba(27, 41, 74, .25);
        backdrop-filter: blur(20%);
        transition: background-color .3s ease-in;

        &::after {
            display: none;
        }

        &:hover {
            background-color: #192847;
        }
    }
}

.btn-link {
    font-weight: 500;
    position: relative;

    &::before,
    &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #D1E3EF;
        position: absolute;
        left: 0;
        bottom: -9px;
        transition: width .3s ease-in, opacity .3s ease-in;
    }

    &::after {
        background-color: #141E37;
        width: 0;
        opacity: 0;
    }

    &:hover {
        &::after {
            width: 100%;
            opacity: 1;
        }
    }
}

.cta-btn {
    background-color: $light-blue;
    color: $white;
    clip-path: polygon(0 0, calc(100% - 48px) 0, 100% 100%, 0 100%);
    padding: 23px 113px 19px 88px;
    font-weight: 400;
    transition: background-color .3s ease-in;
    position: relative;
    z-index: 0;
    display: inline-block;

    @include media('<large') {
        padding: 23px 120px 19px 60px;
    }

    @include media('<medium') {
        padding: 17px 50px 8px 65px;
        font-size: 18px;
        clip-path: polygon(0 0, calc(100% - 37px) 0, 100% 100%, 0 100%);
    }

    &::before {
        content: "";
        background-image: url("/assets/templates/images/svg/icon-arrow-right-btn.svg");
        width: 6px;
        height: 10px;
        background-repeat: no-repeat;
        position: absolute;
        top: 30px;
        left: 67px;
        
        @include media('<large') {
            left: 40px;
        }

        @include media('<medium') {
            left: 49px;
            top: 23px;
        }
    }

    &::after {
        content: "";
        background-color: $light-blue2;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: width .3s ease-in, opacity .3s ease-in;
        z-index: -1;
        opacity: 0;
        clip-path: polygon(0 0, calc(100% - 48px) 0, 100% 100%, 0 100%);
    }

    &:hover {
        color: $white;

        &::before {
            content: "";
            background-image: url("/assets/templates/images/svg/icon-arrow-right-btn-light.svg");
        }

        &::after {
            width: 100%;
            opacity: 1;
        }
    }

}


