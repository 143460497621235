@use "sass:map";

.our-people {
    padding: 190px 0 152px 0;
    background-color: $bg-color;

    @include media('<large') {
        padding: 100px 0 100px 0;
    }

    @include media('<769px') {
        padding: 60px 0;
    }

    h2 {
        font-size: rem-calc(110);
        line-height: lh(75,110);
        color: #D1E3EF;
        margin-bottom: 123px;
        font-weight: 300;

        @include media('<large') {
            font-size: rem-calc(70);
            line-height: lh(75,70);
            margin-bottom: 60px;
        }

        @include media('<769px') {
            font-size: rem-calc(50);
            line-height: lh(75,70);
            margin-bottom: 40px;
        }
    }

    &-block {
        border-bottom: 1px solid #D1E3EF;
        margin-top: 103px;

        @include media('<large') {
            margin-top: 60px;
        }

        @include media('<769px') {
            margin-top: 40px;
        }

        &:last-child {
            border-bottom: 0;

            .our-people-block__wrapper {
                padding-bottom: 0;
            }
        }

        h3 {
            font-size: rem-calc(30);
            line-height: lh(25,30);
            font-weight: 500;
            margin-bottom: 59px;
            color: #ACCBDE;
        }

        &__wrapper {
            display: flex;
            gap: 94px 100px;
            flex-wrap: wrap;
            padding-bottom: 92px;

            @include media('<large') {
                gap: 50px;
            }

            .person-card {
                max-width: 340px;
                width: 340px;

                @include media('<large') {
                    max-width: calc(50% - 25px)
                }

                @include media('<medium') {
                    max-width: 100%;
                    width: 100%;
                }

                &__image {
                    background: linear-gradient(0deg, rgba(255,255,255,1) 77px, rgba(255,255,255,0) 77px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__body {
                    padding: 32px 43px 28px 43px;
                    background-color: $white;

                    .name {
                        display: block;
                        font-size: rem-calc(20);
                        line-height: lh(25,20);
                        font-weight: 500;
                        margin-bottom: 7px;
                    }

                    .function {
                        display: block;
                        font-size: rem-calc(17);
                        line-height: lh(25,17);
                        font-weight: 300;
                        margin-bottom: 13px;
                    }

                    .btn-link {
                        font-size: rem-calc(17);
                        display: inline-block;
                        margin-bottom: 14px;

                        &::before,
                        &::after {
                            bottom: 1px;
                        }
                    }
                }
            }
        }
    }
}
