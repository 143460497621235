@use "sass:map";

.rte-with-sidebar {
    padding: 332px 0 139px 0;

    @include media('<medium') {
        padding: 250px 0 60px 0;
    }

    .content {
        max-width: 674px;
        margin-top: 7px;

        h2 {
            font-size: rem-calc(45);
            line-height: lh(50,45);
            margin-bottom: 45px;
            color: $dark-blue;
            font-weight: 300;
            max-width: 673px;

            @include media('<medium') {
                font-size: rem-calc(30);
                line-height: lh(45,30);
                margin-bottom: 30px;
            }
        }

        h3 {
            font-size: rem-calc(20);
            line-height: lh(25,20);
            margin-bottom: 15px;
            color: $dark-blue;
            font-weight: 700;
            margin-top: 46px;

            @include media('<medium') {
                margin-top: 30px;
                margin-bottom: 5px;
            }
        }

        p {
            font-size: rem-calc(18);
            line-height: lh(30,18);
            margin-bottom: 30px;

            @include media('<medium') {
                font-size: rem-calc(16);
                line-height: lh(25,16);
                margin-bottom: 1rem;
            }
        }

        ul {
            list-style: none;
            display: flex;
            margin-left: 0;
            flex-direction: column;
            margin-top: 43px;
            margin-bottom: 34px;

            @include media('<medium') {
                margin-top: 30px;
                margin-bottom: 30px;
            }

            li {
                font-size: rem-calc(16);
                line-height: lh(25,16);
                font-weight: 300;
                padding-left: 22px;
                position: relative;
                max-width: 500px;
                margin-bottom: 9px;

                &::before {
                    content: "";
                    background-image: url("/assets/templates/images/svg/icon-check.svg");
                    position: absolute;
                    width: 14px;
                    height: 12px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    top: 7px;
                    left: -1px;
                }
            }
        }

        .btn-link {
            font-size: rem-calc(17);
        }
    }

    .sidebar-column {
        display: flex;
        align-items: flex-start;

        .cta-block {
            max-width: 404px;
            position: sticky;
            top: 140px;
            margin-left: auto;

            @include media('<medium') {
                margin-top: 50px;
                margin-left: 0;
            }

            &__inner {
                padding: 52px 41px 33px 53px;

                @include media('<large') {
                    padding: 40px 30px 40px 40px;
                }

                @include media('<769px') {
                    padding: 20px; //38px 30px 44px 40px;
                }

                h2 {
                    margin-bottom: 59px;

                    @include media('<large') {
                        margin-bottom: 40px;
                    }

                    @include media('<medium') {
                        font-size: rem-calc(20);
                        line-height: lh(35,20);
                        margin-bottom: 56px;
                    }
                }
            }

            &__details div {
                margin-left: 19px;
            }
        }
    }
}
