@use "sass:map";

.vacancies-overview {
    padding-bottom: 149px;

    @include media('<769px') {
        padding-bottom: 60px;
    }

    .vacancies-wrapper {
        display: flex;
        gap: 80px 60px;
        flex-wrap: wrap;

        @include media('<large') {
            gap: 40px;
        }

        .vacancy-item {
            width: calc(50% - 30px);
            position: relative;

            @include media('<large') {
                width: calc(50% - 20px);
            }

            @include media('<500px') {
                width: 100%;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }

                .btn-link {
                    &::after {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }

            &::before {
                content: "";
                //background: linear-gradient(rgba(20, 30, 55, 0) 0%, #141e37 35%);
                background: linear-gradient(rgba(27, 41, 74, 0) 25%, #1b294a 47%);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;

                @include media('<large') {
                    background: linear-gradient(rgba(27, 41, 74, 0) 25%, #1b294a 37%);
                }
            }

            &__image {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;

                img {
                    transition: transform .2s ease-in;
                }
            }

            &__body {
                padding: 299px 74px 68px 68px;
                z-index: 1;
                position: relative;

                @include media('<large') {
                    padding: 220px 40px 40px 40px;
                }

                .subtitle {
                    color: $yellow;
                    font-size: rem-calc(18);
                    line-height: lh(25,18);
                    margin-bottom: 10px;
                    display: block;
                    font-weight: 500;
                }

                h2 {
                    color: $white;
                    font-size: rem-calc(35);
                    line-height: lh(45,35);
                    font-weight: 400;
                    margin-bottom: 20px;
                    min-height: 135px;

                    @include media('<large') {
                        font-size: rem-calc(30);
                        line-height: lh(40,30);
                    }

                    @include media('<medium') {
                        min-height: auto;
                        word-break: break-word;
                        font-size: rem-calc(25);
                        line-height: lh(30,25);
                    }
                }

                .btn-link {
                    color: $white;
                    font-size: rem-calc(17);

                    &::after {
                        background-color: $yellow;
                    }
                }
            }
        }
    }
}
