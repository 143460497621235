@use "sass:map";

$sidespace: calc((100vw - 1235px) / 2);

.image-with-info-points {
    padding: 0 0 137px 0;

    > .row > .columns {
        transition: max-width .2s ease-in;
    }

    @include media('<medium') {
        padding: 0 0 60px 0;
    }

    .info-points-wrapper {
        margin-left: -4px;
        margin-right: -4px;
        position: relative;
        transition: max-width .3s ease-in, margin .3s ease-in;
        max-width: calc(100% + 8px);

        &.shrink {
            max-width: 929px;
            margin-left: 0;
            margin-top: -21px;
        }

        .overlay-points {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            .overlay-point {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;

                .point {
                    width: 23px;
                    height: 23px;
                    background-color: #fff;
                    border-radius: 90px;
                    border: 6px solid $yellow;
                    box-shadow: 0 0 0 10px rgba($yellow, .55);
                    transform: translate(-50%, -50%) scale(1);
                    transition: transform ease-in-out .3s;

                    &:hover {
                        transform: translate(-50%, -50%) scale(1.2);
                    }

                    @include media('<769px') {
                        width: 18px;
                        height: 18px;
                        box-shadow: 0 0 0 8px rgba($yellow, .55);
                    }
                }
            }
        }
    }
}

.info-popup {
    background-color: $white;
    position: fixed;
    right: -621px;
    top: 0;
    //min-width: 621px;
    //max-width: 621px;
    width: 621px;
    bottom: 0;
    z-index: 999;
    transition: right .3s ease-in;
    padding: 205px 74px 80px 84px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;
    border-left: 1px solid $bg-color;

    &.show {
        right: -15px;
        width: 636px;

        @include media('<1281px') {
            width: 500px;
        }

        @include media('<1168px') {
            width: 400px;
            padding: 100px 40px 40px 40px;
        }

        @include media('<large') {
            width: 50%;
        }

        @include media('<medium') {
            width: 100%;
            padding: 20px;
            right: 0;
        }
    }

    &-inner {
        transition: opacity .3s ease-in;
    }

    &__close {
        background-color: $bg-color;
        width: 68px;
        height: 56px;
        background-image: url("/assets/templates/images/svg/icon-popup-close.svg");
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
        display: block;
        margin-left: 4px;
        margin-bottom: 40px;

        @include media('<medium') {
            margin-bottom: 20px;
        }
    }

    h3 {
        color: $yellow;
        font-size: rem-calc(50);
        line-height: lh(30,50);
        margin-bottom: 30px;
        font-weight: 500;

        @include media('<medium') {
            font-size: rem-calc(32);
            margin-bottom: 20px;
        }
    }

    b {
        margin-bottom: 67px;
        font-size: rem-calc(20);
        line-height: lh(25,20);
        display: block;

        @include media('<medium') {
            margin-bottom: 20px;
            font-size: rem-calc(16);
            line-height: lh(25,16);
        }
    }

    img {
        margin-bottom: 50px;

        @include media('<medium') {
            margin-bottom: 20px;
        }
    }

    p {
        font-size: rem-calc(17);
        line-height: lh(25,17);
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 42px;

            @include media('<medium') {
                margin-bottom: 25px;
                font-size: rem-calc(16);
                line-height: lh(25,16);
            }
        }
    }

    .btn-link {
        font-size: rem-calc(17);

        &::before,
        &::after {
            bottom: 1px;
        }
    }
}
