@font-face {
  font-family: 'Roboto';
  src: url('../fonts/../fonts/../fonts/subset-Roboto-Light.woff2') format('woff2'),
  url('../fonts/../fonts/../fonts/subset-Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-ThinItalic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Bold.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Italic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Thin.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Black.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Medium.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-BlackItalic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-LightItalic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-BoldItalic.woff2') format('woff2'),
  url('../fonts/subset-Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?tv93qo');
  src:  url('../fonts/icomoon/icomoon.eot?tv93qo#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.ttf?tv93qo') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?tv93qo') format('woff'),
  url('../fonts/icomoon/icomoon.svg?tv93qo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wilhelm:before {
  content: "\e900";
  color: #2c385e;
}

.icon-icon-play:before {
  content: "\e901";
  color: #fff;
}
