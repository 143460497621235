@use "sass:map";

.process-bullets {
    padding: 249px 0 150px 0;
    position: relative;
    background-size: cover;

    @include media('<large') {
        padding: 120px 0;
    }

    @include media('<medium') {
        padding: 60px 0 105px 0;
    }

    .columns {
        z-index: 1;
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, rgba(238,170,2,1) 0%, rgba(238,170,2,0.84) 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    h2 {
        color: $yellow-dark;
        font-size: rem-calc(110);
        line-height: lh(75,110);
        margin-bottom: 20px;
        font-weight: 300;

        @include media('<large') {
            font-size: rem-calc(90);
        }

        @include media('<medium') {
            font-size: rem-calc(60);
            line-height: lh(75,60);
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 108px;
        position: relative;

        @include media('<medium') {
            flex-direction: column;
            padding-top: 49px;
        }

        &::before {
            content: "";
            background-image: url("/assets/templates/images/svg/bullets-line.svg");
            width: 4px;
            height: calc(100% + 150px);
            position: absolute;
            top: -1px;
            left: 38px;
            z-index: -1;

            @include media('<large') {
                height: calc(100% + 120px);
            }

            @include media('<medium') {
                height: 100%;
                left: 33px;
                top: 4px;
            }
        }

        .bullet-list {
            list-style: none;
            margin: 0;
            margin-left: 7px;

            li {
                display: flex;
                align-items: center;
                color: $white;
                font-size: rem-calc(25);
                line-height: lh(25,25);
                font-weight: 500;
                margin-bottom: 44px;

                @include media('<medium') {
                    font-size: rem-calc(20);
                    line-height: lh(25,20);
                    margin-bottom: 38px;
                }

                .icon {
                    background-color: $yellow-dark;
                    width: 68px;
                    height: 56px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 40px;
                    margin-top: -2px;

                    @include media('<medium') {
                        width: 58px;
                        height: 47px;
                        margin-right: 34px;
                    }
                }
            }
        }

        .process-sidebar {
            margin-top: -243px;
            max-width: 469px;

            @include media('<large') {
                margin-top: -110px;
                max-width: 400px;
            }

            @include media('<medium') {
                margin-top: 89px;
            }

            &__body {
                padding: 68px 68px 75px 68px;
                background-color: $yellow-dark;
                position: relative;
                margin-top: -130px;

                @include media('<large') {
                    padding: 40px 40px 46px 40px;
                    margin-top: -38px;
                }

                &::before {
                    content: "";
                    background: linear-gradient(180deg, rgba(201,135,9,0) 0%, rgba(201,135,9,1) 100%);
                    position: absolute;
                    top: -170px;
                    left: 0;
                    height: 170px;
                    width: 100%;
                }

                p {
                    color: $white;
                    font-size: rem-calc(35);
                    line-height: lh(45,35);
                    margin-bottom: 31px;
                    font-weight: 400;

                    @include media('<medium') {
                        font-size: rem-calc(20);
                        line-height: lh(30,20);
                    }
                }

                .btn-link {
                    color: $white;
                    font-size: rem-calc(17);

                    &::after {
                        background-color: $yellow;
                    }
                }
            }
        }
    }
}
