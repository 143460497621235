@use "sass:map";

.video-header {
    height: 752px;
    position: relative;
    overflow: hidden;

    @include media('<1500px') {
        height: 500px;
    }

    @include media('<medium') {
        height: 400px;
    }

    &::before {
        content: "";
        background: linear-gradient(0deg, rgba(27,41,74,0) 0%, rgba(27,41,74,1) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    a {
        position: relative;
        z-index: 2;
    }

    .play-video {
        margin-top: 645px;
        z-index: 2;
        position: relative;
        line-height: lh(24,18);
        padding: 18px 33px 14px 33px;
        color: $white;
        background-color: rgba($bg-color, .3);
        border-radius: 40px;
        font-weight: 400;
        transition: background-color .3s ease-in;
        display: inline-block;

        @include media('<1500px') {
            margin-top: 423px;
        }

        @include media('<medium') {
            margin-top: 300px;
        }

        &::before {
            content: "";
            background-image: url("/assets/templates/images/svg/icon-play.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 10px;
            height: 12px;
            position: relative;
            top: -2px;
            margin-right: 15px;
        }

        &:hover {
            background-color: $white;
            color: $black;

            &::before {
                background-image: url("/assets/templates/images/svg/icon-play-black.svg");
            }
        }
    }

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //top: 0;
        //left: 0;
        width: 100vw;
        height: 100vh;
        aspect-ratio: 16/9;

        @include media('<1500px') {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
