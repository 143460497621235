@use "sass:map";

.product-category-overview {
    //padding-bottom: 80px;
    padding: 8px 0 85px 0;

    @include media('<769px') {
        //padding-bottom: 60px;
        padding: 0 0 60px 0;
    }

    .category-block {
        display: flex;
        border-top: 1px solid $lighter-blue;
        padding: 60px 0;

        @include media('<large') {
            flex-direction: column;
        }

        @include media('<769px') {
            padding: 30px 0 30px 0;
        }

        h2 {
            color: $lighter-blue;
            font-size: rem-calc(45);
            line-height: lh(75,45);
            width: 367px;
            margin-top: -6px;

            @include media('<1250px') {
                width: 250px;
            }

            @include media('<769px') {
                font-size: rem-calc(30);
            }
        }

        &-wrapper {
            display: flex;
            //margin-top: 6px;
            gap: 39px 40px;
            flex: 1;
            flex-wrap: wrap;

            @include media('<769px') {
                gap: 20px;
            }

            .product-item {
                display: flex;
                background-color: $white;

                img {
                    margin: 8px;

                    @include media('<medium') {
                        width: 80px;
                    }
                }

                &__details {
                    padding: 33px 28px 24px 27px;
                    border-left: 1px solid $lighter-blue;

                    @include media('<769px') {
                        padding: 20px;
                        flex: 1;
                    }

                    h3 {
                        font-size: rem-calc(25);
                        line-height: lh(25,25);
                        margin-bottom: 7px;
                        font-weight: 500;

                        @include media('<769px') {
                            font-size: rem-calc(16);
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }

                    .btn-link {
                        font-size: rem-calc(17);
                        font-weight: 300;

                        &::before,
                        &::after {
                            bottom: -1px;
                        }
                    }
                }
            }
        }
    }
}
