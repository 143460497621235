@use "sass:map";

.cta-with-form {
    padding: 150px 0 145px 0;
    background-color: $bg-color;

    @include media('<769px') {
        padding: 60px 0 60px 0;
    }

    &.variant {
        background-color: $white;

        .form-wrapper {
            padding: 7px 0 0 30px;

            @include media('<789px') {
                padding: 0;
            }

            h2 {
                margin-bottom: 49px;

                @include media('<medium') {
                    margin-bottom: 20px;
                }
            }

            .form-pagination button[type=submit] {
                margin-top: 26px;
            }
        }
    }

    .form-wrapper {
        max-width: 651px;
        padding: 80px 76px 76px 76px;
        background-color: $white;
        margin-left: auto;

        @include media('<large') {
            padding: 40px;
        }

        @include media('<medium') {
            margin-top: 50px;
            margin-left: 0;
            padding: 42px 43px; //40px 20px;
        }

        h2 {
            color: $yellow;
            font-size: rem-calc(30);
            line-height: lh(25,30);
            margin-bottom: 48px;
            font-weight: 500;

            @include media('<medium') {
                margin-bottom: 20px;
                font-size: rem-calc(25);
            }
        }
    }

    &.dark-title {
        padding: 62px 0 170px 0;

        .form-wrapper {
            padding-top: 78px;
            h2 {
                font-size: rem-calc(25);
                line-height: lh(25,25);
                color: $dark-blue;
                margin-bottom: 46px;
            }
        }
    }

    &.vacancy {
        padding: 150px 0 150px 0;
    
        @include media('<large') {
            padding: 100px 0;
        }
        
        @include media('<medium') {
            padding: 60px 0 60px 0;
        }

        .form-wrapper {
            padding-top: 4px;
        }

        .file-upload {
            margin-bottom: 10px;

            span {
                display: block;
                margin: 0;
                font-size: rem-calc(18);
                font-weight: normal;
                line-height: 1.8;
                color: #0a0a0a;
            }

            input {
                //display: none;
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .btn {
                padding: 13px 34px 10px 32px;
                margin-left: -2px;
                cursor: pointer;
                margin-right: 15px;
            }

            .filename {
                font-size: rem-calc(18);
                line-height: lh(25,18);
                font-weight: 300;
                margin-top: 17px;
                word-break: break-word;
                display: inline-block;
            }

            .form-control--description {
                display: block;
                font-size: rem-calc(10);
                line-height: lh(25,10);
                font-weight: 300;
                margin-top: 3px;
            }
        }
    }
}
