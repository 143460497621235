@use "sass:map";

.breadcrumb-and-title {
    padding: 248px 0 74px 0;

    @include media('<large') {
        padding: 150px 0 60px 0;
    }

    @include media('<medium') {
        padding: 150px 0 30px 0;
    }

    h1 {
        color: $darker-blue;
        font-size: rem-calc(50);
        line-height: lh(55,50);

        @include media('<medium') {
            font-size: rem-calc(30);
            line-height: lh(30,30);
        }

    }

    p {
        max-width: 661px;
        margin-bottom: 0;
    }

    &.with-intro {
        h1 {
            margin-bottom: 53px; //46px;
        }
    }

    &.with-nav {
        padding: 156px 0 190px 0;
        
        @include media('<large') {
            padding: 60px 0;
        }

        @include media('<769px') {
            padding: 60px 0;
        }

        h1 {
            margin-bottom: 55px;

            @include media('<medium') {
                margin-bottom: 20px;
            }
        }

        p {
            font-size: rem-calc(20);
            line-height: lh(35,20);
            max-width: 663px;
        }

        .columns {
            &:last-child {
                position: relative;
            }
        }

        .sidebar-nav-wrapper {
            @include media('>medium') {
                /* sticky stuff */
                display: flex;
                justify-content: space-around;
                align-items: flex-start;
                min-height: 1000px;
                position: absolute;
                right: .5rem;
                width: 100%;
                z-index: 2;
            }
        }

        .product-navigation {
            max-width: 405px;
            margin-top: -268px;
            margin-left: auto;

            @include media('<large') {
                max-width: 300px;
            }

            @include media('<medium') {
                margin-top: 0;
                margin-left: 0;
                margin-top: 30px;
            }

            ul.tabs li.tabs-title {
                margin-bottom: 2px;
                &.is-active,
                &:hover {
                    a {
                        padding: 31px 20px 22px 65px;
                        background-position: 47px center;

                        @include media('<large') {
                            padding: 22px 20px 16px 38px;
                            background-position: 20px center;
                        }

                        @include media('<medium') {
                            padding: 15px 20px 15px 40px;
                            background-position: 20px center;
                        }
                    }
                }

                a {
                    padding: 31px 20px 22px 47px;

                    @include media('<large') {
                        padding: 22px 20px 16px 38px;
                    }

                    @include media('<medium') {
                        padding: 15px 20px 15px 20px;
                    }
                }
            }
        }
    }
}
