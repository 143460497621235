@use "sass:map";

.contact-form-with-sidebar {
    padding: 100px 0 153px 0;
    background-color: $bg-color;

    @include media('<769px') {
        padding: 47px 0 103px 0;
    }

    .form-wrapper {
        margin-left: 0;

        @include media('<medium') {
            margin-top: 0;
        }

        h2 {
            margin-bottom: 44px;

            @include media('<medium') {
                color: $black;
                font-size: rem-calc(20);
                line-height: lh(25,20);
                margin-bottom: 41px;
            }
        }
    }

    .contact-sidebar {
        max-width: 469px;
        margin-left: auto;
        position: relative;

        @include media('<medium') {
            max-width: 100%;
            margin-left: 0;
            background-size: 100%;
            margin-top: 48px;
            background-repeat: no-repeat;
        }

        &::before {
            content: "";
            background: linear-gradient(rgba(20, 30, 55, 0) 0%, #141e37 35%);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        .map-with-location {
            position: absolute;
            z-index: 1;
            right: 20px;
            top: 188px;

            @include media('<large') {
                top: 100px;
            }

            @include media('<large') {
                top: 140px;
                right: 15px;
            }


            .location-pin {
                position: absolute;
                width: 16px;
                height: 16px;
                border-radius: 16px;
                background: #FFF;
                //filter: drop-shadow(0px 0px 20px #40baec);
                top: 57%;
                left: 33%;
                border: 5px solid #40baec;
                -webkit-box-shadow: 0 0 20px 0 rgba(64,186,236,1);
                -moz-box-shadow: 0 0 20px 0 rgba(64,186,236,1);
                box-shadow: 0 0 20px 0 rgba(64,186,236,1);
            }

            @include media('<large') {
                img {
                    width: 158px;
                }
            }
        }

        &__body {
            padding: 369px 65px 57px 65px;
            position: relative;
            z-index: 1;
            color: $white;

            @include media('<large') {
                padding: 369px 40px 40px 40px;
            }

            @include media('<medium') {
                padding: 276px 20px 22px 49px;
            }

            h3 {
                color: $light-blue;
                font-size: rem-calc(25);
                line-height: lh(25,25);
                margin-bottom: 34px;

                @include media('<medium') {
                    margin-bottom: 22px;
                }
            }

            h4 {
                color: $light-blue;
                font-size: rem-calc(17);
                line-height: lh(25,17);
                margin-bottom: 15px;
                margin-top: 26px;
                font-weight: 400;
            }

            p {
                font-size: rem-calc(17);
                line-height: lh(25,17);
                margin-bottom: 4px;

                @include media('<medium') {
                    line-height: 1;
                }

                + a {
                    margin-top: 28px;

                    @include media('<medium') {
                        margin-top: 18px;
                    }
                }
            }

            a:not(.btn) {
                position: relative;
                color: $white;
                font-size: rem-calc(17);
                line-height: lh(25,17);
                display: inline-block;
                margin-bottom: 8px;

                &::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $input-border;
                    position: absolute;
                    left: 0;
                    bottom: 2px;
                    transition: width .3s ease-in, opacity .3s ease-in;
                }

                &::after {
                    content: "";
                    width: 0;
                    height: 1px;
                    background-color: $yellow;
                    position: absolute;
                    left: 0;
                    bottom: 2px;
                    transition: width .3s ease-in, opacity .3s ease-in;
                }

                &:hover,&:focus {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        .cta-btn {
            z-index: 1;
        }
    }
}

.pulse {
    animation: pulsePin 2s infinite;
}

@keyframes pulsePin {
    0% {
        box-shadow: 0 0 0 0 rgba(64, 186, 236, 0.5);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(64, 186, 236, 0);
    }
}

.ui-datepicker {
    max-width: 499px;
    width: 100% !important;
    border-color: #accbde !important;
    border-radius: 0 !important;
    margin-top: -1px !important;

    @include media('<medium') {
        width: 15em !important;
    }

    .ui-datepicker-header {
        background-color: transparent;
        border: 0;

        .ui-datepicker-prev,
        .ui-datepicker-next {
            display: none;
        }

        .ui-datepicker-title {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 200px;

            @include media('<medium') {
                gap: 20px;
            }

            select {
                border: 0;
            }
        }
    }

    .ui-datepicker-calendar {

        span {
            color: $lighter-blue;
        }
    }

    .ui-state-default {
        border: 0 !important;
        background-color: transparent !important;
        text-align: center !important;
        position: relative;
        top: -2px;

        &::before {
            content: "";
            height: 35px;
            width: 35px;
            position: absolute;
            border-radius: 35px;
            background: black;
            left: 50%;
            z-index: -1;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity .2s ease-in;
            top: -1px;
        }

        &:hover {
            background-color: $dark-blue;
            color: $white;
            position: relative;
            &::before {
                opacity: 1;
            }
        }

        &.ui-state-active {

            &::before {
                opacity: 1;
            }
        }
    }
}

.hide-mobile {
    @include media('<medium') {
        display: none;
    }
}
