@use "sass:map";
@import "../header/header";

.header-with-submenus {

    .menu-item {

        > ul {
            list-style: none;

            > li {
                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }

                > a {
                    color: $white;
                    font-size: rem-calc(17);
                    padding: 15px 20px;
                    border-bottom: 1px solid #000;
                    transition: padding .25s ease-in;

                    &:hover {
                        padding: 15px 0 15px 40px;
                        background-image: url("/assets/templates/images/svg/icon-chevron-right-yellow.svg");
                        background-repeat: no-repeat;
                        background-position: 20px center;
                    }
                }
            }
        }
        &.has-children {
            > a {
                background-image: url("/assets/templates/images/svg/icon-chevron-down-yellow.svg");
                background-repeat: no-repeat;
                background-position: right center;
                padding-right: 20px;
            }
        }
    }

    .has-children {
        position: relative;
        ul {
            display: flex;
            position: absolute;
            margin-left: -20px;
            flex-direction: column;
            width: calc(100% + 40px);
            border-radius: 0 0 20px 20px;

            background-color: $darker-blue;
            transition: max-height .3s ease-in, padding-top .3s ease-in;
            max-height: 0;
            overflow: hidden;
            padding-top: 0; //10px;

            li {
                &:first-child {
                    margin-top: 10px;
                }
            }
        }

        &::before {
            content: "";
            background-color: $darker-blue;
            transition: opacity .3s ease-in;
            border-radius: 20px 20px 0 0;
            width: calc(100% + 40px);
            height: 40px;
            position: absolute;
            left: -20px;
            top: -20px;
            opacity: 0;
        }

        &:hover, &:focus, &:focus-within, &.hover {
            &::before {
                opacity: 1;
            }

            ul {
                padding-top: 10px;
                max-height: 400px;
                background-color: $darker-blue;
            }
        }

        @include media('<large') {
            > a {
                position: relative;

                &:after {
                    content: '\f105';
                    font-family: "Font Awesome 5 Free", fantasy;
                    font-weight: 900;
                    font-size: 1rem;
                    color: inherit;
                    position: absolute;
                    right: -20px;
                    top: 10px;
                }
            }

            &:hover, &:focus, &:focus-within, &.hover {

                ul {
                    box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
}
