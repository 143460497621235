/**
* @param {Number} $value
* @param {Number} $base-value
 */
@function rem-calc($value, $base-value: 16) {
  $value: $value / $base-value * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

/**
* @param {Number} $value
* @param {Number} $font-size
 */
@function lh($value, $font-size) {
  @return $value / $font-size;
}

/**
* @param {Number} $lines
 */
@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
