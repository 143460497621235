@use "sass:map";

.text-centered {
    padding: 44px 0 125px 0;

    @include media('<medium') {
        padding: 25px 0 51px 0;
    }

    .content {
        max-width: 801px;
        margin: 0 auto;

        p {
            font-size: rem-calc(20);
            line-height: lh(35,20);

            @include media('<medium') {
                font-size: rem-calc(15);
                line-height: lh(30,15);
            }
        }
    }
}

.list-wrapper {
    max-width: 806px;
    margin: 84px auto 0 auto;

    @include media('<medium') {
        margin-top: 45px;
    }

    &.aos-animate {
        ul {
            li {
                &::before {
                    animation: pulse-animation 1s infinite;
                    animation-iteration-count: 2;
                }
            }
        }
    }

    ul {
        list-style: none;
        display: flex;
        margin: 0;

        @include media('<medium') {
            flex-direction: column;
        }

        li {
            font-size: rem-calc(16);
            line-height: lh(25,16);
            font-weight: 500;
            padding-left: 25px;
            position: relative;
            margin-right: 40px;

            &.aos-animate {
                &::before {
                    animation: pulse-animation 1s infinite;
                    animation-iteration-count: 2;
                    animation-delay: 1s;
                }
            }

            @include media('<medium') {
                font-size: rem-calc(14);
                line-height: lh(25,14);
                padding-left: 19px;
                margin-bottom: 6px;
            }

            &::before {
                content: "";
                background-image: url("/assets/templates/images/svg/icon-check.svg");
                position: absolute;
                width: 14px;
                height: 12px;
                background-size: 100%;
                background-repeat: no-repeat;
                top: 8px;
                left: 1px;

                @include media('<medium') {
                    width: 12px;
                    height: 12px;
                    top: 6px;
                    left: -1px;
                }

                //animation: pulse-animation 1s infinite;
                //animation-iteration-count: 2;
            }
        }
    }
}

@keyframes pulse-animation {
    0% {
        transform: scale(0.95);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(0.95);
    }
}
