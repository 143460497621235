$fa-font-path: '../fonts';

$root: (
        border-radius: 10px,
        font-family: 'Roboto, sans-serif',
        heading-font-family: 'Roboto, sans-serif',
        font-size: rem-calc(16),
        box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4),
);

/**
Headings
 */
$heading-color: #000;
$h1-font-size: rem-calc(62);
$h2-font-size: rem-calc(40);
$h3-font-size: rem-calc(34);
$h4-font-size: rem-calc(26);
$h5-font-size: rem-calc(22);
$h6-font-size: rem-calc(18);

/**
Suptitle
 */
$suptitle-font-size: rem-calc(17);
$suptitle-font-weight: 700;
$suptitle-color: #50AB41;
$suptitle-line-width: 60px;

/**
Colors
 */
$black:         #000000;
$white:         #FFFFFF;
$yellow:        #EEAA02;
$yellow-dark:   #C98709;
$light-blue:    #40BAEC;
$light-blue2:   #1F96C7;
$lighter-blue:  #ACCBDE;
$dark-blue:     #1B294A;
$darker-blue:   #141E37;
$input-border:  $lighter-blue;
$bg-color:      #E8F1F7;
