@use "sass:map";

.parallax-image-with-quote {
    height: 657px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; //contain;
    position: relative;

    &::before {
        content: "";
        background: linear-gradient(0deg, rgba(209,227,239,0.11) 0%, rgba(27,41,74,0.73) 95%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    @include media('<1920px') {
        background-size: cover;
        background-position: center;
    }
    
    @include media('<769px') {
        height: 500px;
    }

    @supports (-webkit-touch-callout: none) {
        background-attachment: unset;
    }

    .row {
        height: 100%;

        .columns {
            display: flex;
            align-items: flex-end;
        }
    }

    .quote-wrapper {
        padding: 96px 92px 92px 92px;
        background-color: $white;
        max-width: 748px;
        position: relative;
        bottom: -222px;
        background-image: url("/assets/templates/images/svg/quotes.png");
        background-repeat: no-repeat;
        background-position: 51px 39px;
        

        @include media('<medium') {
            padding: 40px;
        }

        blockquote {
            color: $lighter-blue;
            font-size: rem-calc(45);
            line-height: lh(55,45);
            font-weight: 300;
            margin-bottom: 34px;
            padding: 0;
            border: 0;

            @include media('<medium') {
                font-size: rem-calc(30);
                line-height: lh(40,30);
            }
        }

        &__details {
            display: flex;
            align-items: center;
            gap: 45px;

            img {
                border-radius: 100px;
                background-color: $bg-color;
            }

            b {
                position: relative;
                top: 4px;

                @include media('<medium') {
                    font-size: rem-calc(16);
                    line-height: lh(25,16);
                }
            }
        }
    }
}
