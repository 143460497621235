@use "sass:map";
$top-footer-padding: (desktop: 80px 0, tablet: 40px 0, mobile: 20px 0);
$top-footer-background: #EBEBEB;
$h2-font-size: (desktop: rem-calc(19), tablet: rem-calc(18), mobile: rem-calc(16));
$h2-color: #50AB41;

.footer {
    .columns {
        @include media('>1600px') {
            &:nth-child(2) {
                padding-left: 69px;
                margin-top: 2px;
            }

            &:nth-child(3) {
                padding-left: 30px;
                margin-top: 2px;
            }
        }


        @include media('<medium') {
            &.small-6 {
                &:nth-child(2) {
                    padding-right: 10px;
                }
                &:nth-child(3) {
                    padding-left: 7px;

                    h3 {
                        margin-left: -10px;
                        margin-bottom: 13px;
                    }
                }
            }
        }
    }

    h3 {
        color: $white;
        font-size: rem-calc(20);
        line-height: lh(25,20);
        margin-bottom: 36px;

        @include media('<medium') {
            margin-bottom: 4px;
            margin-top: 60px;
            font-size: rem-calc(18);
            line-height: lh(25,18);
        }
    }

    &__text {
        color: $white;
        font-size: rem-calc(35);
        line-height: lh(45,35);
        margin-bottom: 33px;
        font-weight: bold;
        max-width: 340px;

        @include media('<medium') {
            font-size: rem-calc(30);
            line-height: lh(45,30);
            margin-bottom: 22px;
        }
    }

    &__contact {
        @include media('<medium') {
            margin-left: -10px;
        }
        p {
            color: $white;
            font-size: rem-calc(17);
            //line-height: lh(25,17);
            margin-bottom: 29px;

            &:last-child {
                margin-bottom: 0;
            }

            @include media('<medium') {
                font-size: rem-calc(14);
                line-height: lh(25,14);
            }
        }

        a {
            color: $white;
            font-size: rem-calc(17);

            @include media('<medium') {
                font-size: rem-calc(14);
            }
        }
    }

    &__top {
        padding: 126px 0 114px 0;
        background-color: $dark-blue;

        @include media('<769px') {
            padding: 71px 0 51px 0;
        }

        .btn {
            padding: 13px 36px 13px 32px;

            @include media('<medium') {
                padding: 13px 32px 10px 30px;
            }
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                line-height: lh(25,17);
                padding: 1px 0;

                @include media('<medium') {
                    line-height: 1;
                }

                a {
                    color: $white;
                    font-size: rem-calc(17);
                    line-height: lh(25,17);

                    @include media('<medium') {
                        font-size: rem-calc(14);
                        line-height: lh(25,14);
                    }
                }
            }
        }

        a:not(.btn) {
            position: relative;

            &::before {
                content: "";
                width: 0;
                height: 1px;
                background-color: $input-border;
                position: absolute;
                left: 0;
                bottom: -2px;
                transition: width .3s ease-in, opacity .3s ease-in;
            }

            &:hover,&:focus {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    &__bottom {
        padding: 54px 0 41px 0;
        background-color: $darker-blue;
        color: $white;
        font-size: rem-calc(14);
        font-weight: 300;
        position: relative;

        @include media('<large') {
            padding: 30px 0;
        }

        @include media('<medium') {
            padding: 34px 0 16px 0;
        }

        .menu {
            display: inline-block;

            @include media('<medium') {
                width: 100%;
            }

            ul {
                display: flex;
                gap: 21px;
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;
                flex-wrap: wrap;

                //@include breakpoint(small down) {
                @include media('<medium') {
                    justify-content: space-between;
                    margin-top: 0;
                    gap: 0;
                }

                li {
                    a {
                        font-weight: 300;
                        color: $white;
                        padding: 0;

                        @include media('<medium') {
                            font-size: rem-calc(12);
                            line-height: lh(25,12);
                        }

                        &:hover {
                            color: $input-border;
                        }
                    }
                }
            }
        }
    }

    &__copyright {
        margin-right: 52px;
        display: inline-block;

        .year {
            display: inline-block;
            margin: 0 11px 0 5px;
        }

        @include media('<1600px') {
            margin-right: 20px;
        }

        @include media('<large') {
            margin-left: 30px;
        }

        @include media('<medium') {
            margin-left: 0;
            font-size: rem-calc(12);
            line-height: lh(25,12);
        }
    }

    &__socials {
        display: flex;
        gap: 10px;
        margin-left: 77px;
        margin-top: -13px;

        @include media('<1600px') {
            margin-left: 0;
        }

/*         @include media('<large') {
            margin-top: 0;
            margin-left: -60px;
        } */

        @include media('<1025px') {
            margin-top: 10px;
            margin-left: 0;
        }

        @include media('<medium') {
            gap: 7px;
            margin-top: 35px;
        }


        @include media('<414px') {
            margin-bottom: 70px;
        }

        a {
            color: $white;
            background-color: $dark-blue;
            width: 55px;
            height: 40px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color .3s ease-in;
            font-size: rem-calc(17);

            @include media('<medium') {
                background-color: $dark-blue;
                width: 44px;
                height: 31px;
                font-size: rem-calc(12);

                i {
                    margin-top: 6px;
                }
            }

            &:hover {
                background-color: $white;
                color: $dark-blue;
            }
        }
    }

    &__wilhelm {
        font-size: 23px;
        position: absolute;
        left: 60px;
        top: 50px;

        @include media('<1420px') {
            top: 10px;
            left: 10px;
        }

        @include media('<large') {
            top: 26px;
            left: 10px;
        }

        @include media('<medium') {
            display: none;
        }

        &:hover {
            span {
                &::before {
                    color: $light-blue;
                }
            }
        }

        span {
            &::before {
                color: $dark-blue;
                transition: color .2s ease-in;
            }
        }
    }

    &__logo {
        background-color: $white;
        position: absolute;
        right: 0;
        top: 0;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 42px 100%);
        width: 392px;
        padding: 35px 0 35px 128px;
        height: 100%;

        img {
            width: 203px;
        }

        @include media('<1420px') {
            width: 300px;
            padding: 35px 0 35px 70px;
        }

        @include media('<large') {
            height: 91px;
            top: auto;
            bottom: 0;
            padding: 35px 0 35px 100px;

            img {
                width: 150px;
            }
        }

        @include media('<769px') {
            width: 177px;
            height: 55px;
            padding: 15px 40px 10px 33px;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 18px 100%);

            img {
                width: 103px;
            }
        }
    }
}
