@use "sass:map";

.text-with-cta {
    padding: 121px 0 60px 0;
    z-index: 1;
    position: relative;

    @include media('<medium') {
        padding: 121px 0 20px 0;
    }

    .large-text {
        font-size: rem-calc(45);
        line-height: lh(55,45);
        font-weight: 300;
        color: $lighter-blue;

        @include media('<medium') {
            font-size: rem-calc(30);
            line-height: lh(40,30);
            margin-top: 66px;
            margin-bottom: 0;
        }
    }

    .cta-block {
        margin-top: -367px;
        opacity: 0;
        animation: animateCtaBlock ease-in-out .75s;
        animation-fill-mode: forwards;
        animation-delay: 2s;
        margin-left: auto;

        @include media('<medium') {
            margin-top: -287px;
        }
    }

    @include media('<medium') {
        .row {
            .columns {
                &:first-child {
                    order: 2;
                }
                &:last-child {
                    order: 1;
                }
            }
        }
    }
}

.cta-block {
    background-color: $white;
    max-width: 473px;

    @include media('<medium') {
        max-width: 308px;
    }

    @include media('<450px') {
        max-width: 100%;
    }

    &.bg-dark {
        background-color: $dark-blue;
        color: $white;

        a {
            color: $white;
        }

        .cta-block__details img {
            background-color: $darker-blue;
        }
    }

    &__inner {
        padding: 62px 50px 58px 68px;

        @include media('<large') {
            padding: 40px 30px 40px 40px;
        }

        @include media('<769px') {
            padding: 20px;
        }

        h2 {
            font-size: rem-calc(30);
            line-height: lh(44,30);
            font-weight: 300;
            margin-bottom: 103px;
            padding-right: 5px;

            @include media('<large') {
                margin-bottom: 40px;
            }

            @include media('<medium') {
                font-size: rem-calc(20);
                line-height: lh(35,20);
                margin-bottom: 56px;
            }
        }
    }

    &__details {
        display: flex;
        align-items: flex-start;

        img {
            background-color: $bg-color;
            border-radius: 50%;
            width: 130px;

            @include media('<medium') {
                width: 93px;
            }
        }

        .title {
            font-weight: bold;
            line-height: lh(25,18);
            margin-bottom: 21px;
            display: block;

            @include media('<medium') {
                font-size: rem-calc(15);
                line-height: lh(25,15);
                margin-bottom: 11px;
            }
        }

        div {
            width: auto;
            margin-top: -4px;
            flex: 1;
            margin-left: 51px;

            @include media('<large') {
                margin-left: 20px;
            }

            @include media('<medium') {
                margin-left: 21px;
                margin-top: 9px;
            }

            a {
                font-weight: 300;
                font-size: rem-calc(17);
                margin-bottom: 6px;
                display: inline-block;

                @include media('<medium') {
                    font-size: rem-calc(14);
                    line-height: lh(25,14);
                }

                &::before,
                &::after {
                    bottom: 3px;
                }
            }
        }
    }
}

@keyframes animateCtaBlock {
    0% {
        transform: translateY(500px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
