@use "sass:map";

.contact-header {
    width: 100%;
    height: 786px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 285px;

    @include media('<769px') {
        height: 600px;
        padding-top: 222px;
    }

    &::before {
        content: "";
        background: linear-gradient(rgba(27, 41, 74, 0.87) 0%, #141e37 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    h1 {
        color: $white;
        font-size: rem-calc(50);
        line-height: lh(75,50);
        margin-bottom: 21px;

        @include media('<medium') {
            font-size: rem-calc(35);
            line-height: lh(35,35);
        }
    }

    .button-group {
        display: flex;

        @include media('<medium') {
            display: none;
        }

        a {
            color: #fff;
            font-weight: 400;
            display: inline-block;
            //padding: 14px 35px 11px 36px;
            position: relative;
            z-index: 0;
            background: rgba(255, 255, 255, 0.1);

            &::after {
                content: "";
                background-color: $yellow;
                width: 56px;
                height: 100%;
                border-radius: 50px;
                position: absolute;
                top: 0;
                left: 0;
                transition: width .2s ease-in, opacity .1s ease-in;
                z-index: -1;
                opacity: 0;
            }

            &:hover,
            &:focus {
                &::after {
                    width: 100%;
                    opacity: 1;
                }
            }

            &._is-active {
                background-color: $yellow;
            }

            &:first-child {
                border-radius: 28px 0 0 28px;
                border-right: 1px solid $yellow-dark;
                padding: 14px 38px 11px 36px;

                &::after {
                    border-radius: 28px 0 0 28px;
                }

            }

            &:last-child {
                border-radius: 0 28px 28px 0;
                padding: 14px 35px 11px 33px;

                &::after {
                    border-radius: 0 28px 28px 0;
                }
            }
        }
    }

    .team-image-desktop {
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom 1s ease-in;

        @include media('<524px') {
            display: none;
        }
    }

    .team-image-mobile {
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom 1s ease-in;

        @include media('>524px') {
            display: none;
        }
    }

    .columns {
        z-index: 1;
    }

    &.parallax {
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover; //contain;

        @include media('<1920px') {
            background-size: cover;
            background-position: center;
        }

        @supports (-webkit-touch-callout: none) {
            background-attachment: unset;
        }
    }
}
