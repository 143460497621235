@use "sass:map";

$sidespace: calc((100vw - 1235px) / 2);
//$sidespace: calc((100vw - 1220px) / 2);

.history-slider {
    background-color: $bg-color;
    padding: 400px 0 112px 0;

    @include media('<large') {
        padding: 300px 0 60px 0;
    }

    @include media('<769px') {
        padding: 300px 0 60px 0;
    }

    .columns {
        padding-left: $sidespace;
        padding-right: 0;

        @include media('<1280px') {
            padding-left: 40px;
        }
    }

    h2 {
        color: $lighter-blue;
        font-size: rem-calc(110);
        line-height: lh(75,110);
        margin-bottom: 64px;
        font-weight: 500;

        @include media('<large') {
            font-size: rem-calc(70);
            line-height: lh(75,70);
            margin-bottom: 40px;
        }

        @include media('<769px') {
            font-size: rem-calc(50);
            line-height: lh(75,50);
            margin-bottom: 20px;
        }
    }

    &__wrapper {
        .glide-history-slider {

        }

        .glide__slides {

        }

        .glide__slide {
            height: auto;
            padding-bottom: 140px;
            margin-bottom: 100px;
            position: relative;

            @include media('<large') {
                margin-bottom: 50px;
            }

            &:last-child {

                .history-slide {
                    &::after {
                        display: none;
                        width: 50%;
                    }
                }
            }
        }

        .history-slide {
            max-width: 403px;
            background-color: $white;
            height: 100%;

            &::before {
                content: "";
                width: 68px;
                height: 56px;
                border-radius: 50px;
                background-color: $white;
                position: absolute;
                bottom: 6px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url("/assets/templates/images/svg/icon-plus.svg");
                background-repeat: no-repeat;
                background-position: center;
            }

            &::after {
                content: "";
                width: calc(100% + 57px);
                height: 2px;
                background-color: $white;
                position: absolute;
                left: 50%;
                bottom: 32px;
                z-index: -1;
            }

            +.history-plus-icon {
                background-color: $white;
                width: 68px;
                height: 56px;
            }

            &__inner {
                height: 100%;
            }

            &__image {
                overflow: hidden;

                img {
                    transition: transform .3s ease-in;
                }

            }

            &__body {
                padding: 63px 54px 55px 54px;
                height: 100%;

                @include media('<large') {
                    padding: 40px;
                }

                .year {
                    display: block;
                    color: $yellow;
                    font-size: rem-calc(35);
                    line-height: lh(30,35);
                    margin-bottom: 15px;
                    font-weight: 500;
                }

                h3 {
                    font-size: rem-calc(20);
                    line-height: lh(25,25);
                    font-weight: 700;
                    margin-bottom: 30px;
                }

                p {
                    font-size: rem-calc(18);
                    line-height: lh(25,18);
                    margin-bottom: 0;
                }
            }

            &:hover {
                .history-slide__image {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .glide {
            &__arrows {
                position: absolute;
                bottom: -30px;
                left: 0;
                gap: 25px;
                display: flex;
                border-radius: 50px;

                @include media('<large') {
                    position: relative;
                    bottom: 0;
                }
            }

            &__arrow {
                position: relative;
                width: 68px;
                height: 54px;
                background-repeat: no-repeat;
                top: 0;
                transform: none;
                transition: background .2s ease-in;
                background-color: #D1E3EF;
                border-radius: 50px;
                box-shadow: none;
                border: 2px solid #D1E3EF;

                &:hover {
                    background-color: $dark-blue;

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right-white.svg");
                    }
                }

                &::before {
                    content: "";
                    width: 10px;
                    height: 18px;
                    background-size: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    top: calc(50% + 1px);
                    transform: translateY(-50%);
                }

                &--left {
                    left: auto;
                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right.svg");
                        transform: scaleX(-1) translateY(-50%);
                        left: 28px;
                    }
                }

                &--right {
                    right: auto;

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-right.svg");
                        right: 28px;
                    }
                }

                &--disabled {
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }

                    &::before {
                        background-image: url("/assets/templates/images/svg/history-slider-arrow-left-disabled.svg");
                        transform: scaleX(1) translateY(-50%);
                        left: 28px;
                    }

                    &.glide__arrow--right {
                        &::before {
                            background-image: url("/assets/templates/images/svg/history-slider-arrow-left-disabled.svg");
                            transform: scaleX(-1) translateY(-50%);
                            left: 28px;
                        }
                    }
                }
            }
        }
    }

    &.vacancy {
        padding: 99px 0 180px 0;

        @include media('<large') {
            padding: 60px 0;
        }

        h2 {
            font-size: rem-calc(70);
            line-height: lh(75,70);
            font-weight: 500;
            margin-bottom: 41px;

            @include media('<large') {
                font-size: rem-calc(50);
                line-height: lh(50,50);
                margin-bottom: 20px;
            }

            @include media('<medium') {
                font-size: rem-calc(30);
                line-height: lh(40,30);
                margin-bottom: 20px;
            }
        }

        p {
            font-size: rem-calc(17);
            line-height: lh(25,17);
        }
    }
}
